import React from 'react';

import { Add, Remove } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import styles from './ItemQuantity.module.scss';

type ItemQuantityComponentProps = {
    amount: number;
    isAvailable: boolean;
    setAmount: (amount: number) => void;
};

export const ItemQuantityComponent: React.FC<ItemQuantityComponentProps> = ({ amount, isAvailable, setAmount }) => {
    return (
        <div className={styles.root}>
            <IconButton
                aria-label="plusAmount"
                className={amount <= 1 ? styles.disabled : styles.button}
                disabled={amount <= 1}
                onClick={() => {
                    if (amount > 1) {
                        setAmount(amount - 1);
                    }
                }}
                size="small"
            >
                <Remove />
            </IconButton>
            <div className={styles.amount}>{amount}</div>
            <IconButton
                aria-label="minusAmount"
                className={!isAvailable ? styles.disabled : styles.button}
                disabled={!isAvailable}
                onClick={() => setAmount(amount + 1)}
                size="small"
            >
                <Add />
            </IconButton>
        </div>
    );
};
