import React from 'react';

import { isHunger } from '../../util/constants';

import styles from './Agb.module.scss';

const Agb: React.FC = () => {
    const fullLegalName = isHunger ? `Best Kassensysteme Solutions` : `BestellEck UG (haftungsbeschränkt)`;
    const address = isHunger
        ? `Friedrich-Ebert Straße 67, 64342 Seeheim-Jugenheim`
        : `Rathausstraße 133A, 68519 Viernheim`;
    const companyName = isHunger ? 'hunger-hunger' : 'BestellEck';
    return (
        <div lang={'de'} className={styles.Agb}>
            <h1 className={styles.headerText}>{'Allgemeine Geschäftsbedingungen'}</h1>
            <h2 className={styles.headerText}>1. Geschäftsgegenstand</h2>
            <p>
                {companyName} stellt eine Online-Plattform zur Abwicklung von Bestellungen bei Restaurants bereit. Der
                Kunde (Nutzer der Webseite oder App) kann über {companyName} Speisen und Getränke bei verfügbaren
                Restaurants bestellen. {companyName} nimmt dabei die Bestellung entgegen, speichert die Daten und
                übermittelt die Bestellung an das Restaurant.
            </p>
            <h2 className={styles.headerText}>2. Geltungsbereich</h2>
            <p>
                Die Allgemeine Geschäftsbedingungen gelten für alle Bestellungen, die über {companyName} abgegeben
                werden. Durch das Abschicken einer Bestellung schließt der Kunden einen verbindlichen Vertrag mit dem
                Restaurant ab.
                {companyName} ist nicht für das Angebot und den Vertrag zwischen dem Kunden und dem Restaurant
                verantwortlich.
            </p>
            <h2 className={styles.headerText}>3. Angebot</h2>
            <p>
                Das Restaurant ist für die bereitgestellten Informationen auf der Online-Plattform zuständig und{' '}
                {companyName}
                handelt nicht im Namen der Restaurants. Die Restaurantinformationen werden von {companyName} nicht auf
                Richtigkeit oder Vollständigkeit überprüft und {companyName} ist nicht für die Durchführung des
                Vertrages zwischen dem Kunden und dem Restaurant verantwortlich.
            </p>
            <p>
                Ein Restaurant hat die Möglichkeit, Zutaten und Zusatzstoffe für Speisen und Getränke einzutragen. Um
                weitere Informationen zu den Zutaten und den Zusatzstoffen zu erhalten, wenden Sie sich an das
                Restaurant.
            </p>
            <h2 className={styles.headerText}>4. Vertragliche Leistung</h2>
            <p>
                {companyName} stellt lediglich die Online-Plattform zur Verfügung und vermittelt eine Bestellung vom
                Kunden an das Restaurant. Für den Kaufvertrag zwischen dem Kunden und dem Restaurant übernimmt{' '}
                {companyName} keine Gewährleistung.
            </p>
            <p>
                Der Vertrag zwischen dem Kunden und dem Restaurant kommt verbindlich zustanden, sobald der Kunde die
                Bestellung über die Online-Plattform aufgibt.
            </p>
            <p>
                Die Bestellung vom Kunden wird an das Restaurant weitergeleitet. Der Kunde ist für die Bereitstellung
                korrekter und vollständiger Kontaktdaten zuständig und hat bei Fehlern das Restaurant unverzüglich
                darüber zu informieren. Hierzu sollte der Kunde sich telefonisch an das Restaurant wenden. Der Kunde
                muss telefonisch unter der angegebenen Telefonnummer erreichbar sein.
            </p>
            <p>
                Das Restaurant prüft die Bestellung und ist berechtigt, Bestellungen abzulehnen, wenn das Angebot nicht
                mehr verfügbar ist, der Kunde falsche Informationen angegeben hat oder wenn ein Fall von höherer Gewalt
                vorliegt.
            </p>
            <p>
                Sobald das Restaurant die Bestellung angenommen oder abgelehnt hat, wird der Kunde elektronisch darüber
                informiert.
            </p>
            <p>
                Wurde Lieferung als Bestellart gewählt, so muss der Kunde an der angegebenen Lieferadresse anwesend
                sein. Ist die Bestellart Abholung gewählt worden, so hat der Kunde die Bestellung zum genannten
                Zeitpunkt und Abholungsort des Restaurants abzuholen.
            </p>
            <p>
                Wurden Produkte, die eine Altersgrenze enthalten, bestellt (z.B. alkoholische Getränke), so kann das
                Restaurant die Vorlage einer Altersidentifizierung fordern. Kann sich der Kunde nicht entsprechend
                ausweisen oder erfüllt er nicht die Altersanforderungen, kann das Restaurant die Aushändigung des
                Produktes an den Kunden verweigern und Stornokosten in Höhe des Kaufpreises für das Produkt mit
                Altersgrenze in Rechnung stellen.
            </p>
            <p>
                {companyName} übernimmt keine Haftung für die Ausführung des Vertrages. Bei Fragen oder Beschwerden zu
                einer Bestellung ist das Restaurant direkt zu kontaktieren. Darüber hinaus kann auch eine Beschwerde
                über ein Restaurant an {companyName} unter kontakt@{companyName}.de geschickt werden.
            </p>
            <h2 className={styles.headerText}>5. Ablehnung von Bestellungen</h2>
            <p>
                Aufgrund der Verderblichkeit von Speisen und Getränken kann der Vertrag nicht von einem Kunden aufgelöst
                werden. Eine Stornierung der Bestellung gegenüber dem Restaurant ist ebenfalls nicht möglich.
            </p>
            <p>
                Das Restaurant ist berechtigt, Bestellungen abzulehnen, wenn das Angebot nicht mehr verfügbar ist, der
                Kunde falsche Informationen angegeben hat oder wenn ein Fall von höherer Gewalt vorliegt. Das Restaurant
                kann auch Bestellungen von einem Kunden sperren, wenn entsprechende Gründe vorliegen.
            </p>
            <h2 className={styles.headerText}>6. Ablehnung, Änderung, Löschung und Ausschluss</h2>
            <p>
                {companyName} behält sich jederzeit das Recht (ohne Angaben von Gründen) vor Inhalte oder Angebote, die
                gegen geltendes Recht verstoßen oder im Widerspruch zu den Allgemeinen Geschäftsbedingungen stehen,
                Kunden abzulehnen, ganz oder teilweise zu löschen bzw. zu sperren. Restaurants können in diesem Fall
                dauernd oder temporär von der Online-Plattform von {companyName} ausgeschlossen werden.
            </p>
            <h2 className={styles.headerText}>7. Gewährleistung</h2>
            <p>
                Mängel bzw. Reklamationen können nur sofort bei der Anlieferung bzw. Abholung geltend gemacht werden und
                sind direkt an das Restaurant zu richten. Ansprüche wegen Mängeln von Lieferungen und Leistungen richten
                sich nach den Vorschriften des BGB.
            </p>
            <h2 className={styles.headerText}>8. Widerrufsrecht</h2>
            <p>
                Der Kunde kann Bestellungen gegenüber dem Restaurant nicht widerrufen, wenn einer der folgenden Punkte
                zutrifft:
            </p>
            <ul>
                <li>
                    Waren (Speisen und Getränke), die nicht vorgefertigt oder auf die persönlichen Bedürfnisse der
                    Verbraucher zugeschnitten sind.
                </li>
                <li>Waren (Speisen und Getränke), die aufgrund ihrer Zubereitung schnell verderben können.</li>
                <li>
                    Waren (Speisen und Getränke), die aufgrund von hygienischen oder gesundheitlichen Aspekten nicht zur
                    Rückgabe geeignet sind.
                </li>
                <li>Waren (Speisen und Getränke), die sich mit anderen Waren untrennbar vermischt haben.</li>
            </ul>
            <p>
                Für Bestellungen, die nicht aufgrund der oben genannten Gründen aus dem Widerrufsrecht ausgeschlossen
                sind, steht dem Kunden ein Widerrufsrecht zu.
            </p>
            <p>
                Um den Vertrag zu widerrufen, senden Sie uns eine Nachricht an {fullLegalName}, {address} oder per Mail
                an kontakt@{companyName}.de.
            </p>
            <h2 className={styles.headerText}>9. Haftung</h2>
            <p>
                {companyName} haftet nicht für das Angebot, die Richtigkeit und Vollständigkeit der
                Restaurantinformationen, die Durchführung des Vertrags und/oder die Erreichbarkeit der Plattform.
            </p>
            <h2 className={styles.headerText}>10. Sonstiges</h2>
            <p>Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.</p>
            <p>
                Sollte eine der Bestimmungen dieser AGB ganz oder teilweise unwirksam sein oder werden, so wird hiervon
                nicht die Wirksamkeit der übrigen Bestimmungen berührt.
            </p>
            <p>
                <br></br>Stand: 15.08.2021
            </p>

            <div>
                <p>Diese Allgemeinen Geschäftsbedingungen gelten für Online-Bestellungen von:</p>
                <p>Menuewaehrung: EUR</p>
                <p>
                    (im Folgenden als &quot;Restaurant&quot; oder &quot;Wir&quot; oder &quot;Uns&quot; bezeichnet)
                    <br></br> Diese Allgemeinen Geschäftsbedingungen gelten für Sie als Kunde für Online-Bestellungen
                    (im Folgenden als „Sie“ oder „Benutzer“ oder „Kunde“ bezeichnet)
                </p>
                <p>
                    BITTE LESEN SIE DIESE GESCHÄFTSBEDINGUNGEN SORGFÄLTIG DURCH. WENN SIE NICHT AN DIESE
                    GESCHÄFTSBEDINGUNGEN GEBUNDEN WOLLEN, SOLLTEN SIE NICHT AUF DIE ONLINE-BESTELLUNG ZUGREIFEN UND /
                    ODER SIE BENUTZEN (DES WEITEREN „ONLINE-BESTELL-APP“). DER ZUGRIFF AUF UND/ODER DIE NUTZUNG DER
                    OLNINE BESTELLUNG GILT ALS AKZEPTIERUNG DIESER BEDINGUNGEN.
                </p>
                <p>
                    Vorbehaltlich der geltenden gesetzlichen Bestimmungen können wir nach eigenem Ermessen von Zeit zu
                    Zeit die Nutzungsbedingungen ändern oder anderweitig modifizieren. Wir empfehlen Ihnen, bei jeder
                    Online-Bestellung die Allgemeinen Geschäftsbedingungen sorgfältig zu lesen, da diese Ihre Rechte
                    beeinflussen können.{' '}
                </p>
                <p>
                    Diese Allgemeinen Geschäftsbedingungen beschreiben die allgemeinen Online-Bestellbedingungen
                    zwischen uns und Ihnen sowie verschiedene Rechte und Pflichten der Parteien.
                    <p>
                        Vorbehaltlich der geltenden gesetzlichen Bestimmungen können wir nach eigenem Ermessen von Zeit
                        zu Zeit die Nutzungsbedingungen ändern oder anderweitig modifizieren. Wir empfehlen Ihnen, bei
                        jeder Online-Bestellung die Allgemeinen Geschäftsbedingungen sorgfältig zu lesen, da diese Ihre
                        Rechte beeinflussen können.{' '}
                    </p>
                </p>
                <p>
                    Aus Gründen der Klarheit wird klargemacht, dass diese Allgemeinen Geschäftsbedingungen (auch) für
                    Online-Bestellungen über die App gelten (im Folgenden als „Online-Bestell-App“ oder die „App“
                    bezeichnet; die Anwendungen in der Online-Bestell-App des Kunden, um Bestellungen für verschiedene
                    Produkte und /oder Dienstleistungen aufzugeben (im Folgenden als &quot;Produkte&quot; und / oder
                    &quot;Dienstleistungen&quot; bezeichnet)).
                </p>
                <p>
                    Die Online-Bestell-App ist Eigentum des Lizenzgebers der Lizenzvereinbarung, bezüglich der
                    Verwendung der App zur Online-Bestellung und wird von diesem betrieben.<br></br> Ohne die
                    Allgemeinheit dieser Allgemeinen Geschäftsbedingungen zu beeinträchtigen und der Klarheit halber
                    wird klargestellt, dass Sie auch die Lizenzvereinbarung bezüglich der Verwendung der App einhalten
                    müssen, um online bestellen zu können.
                </p>
                <p>
                    Das Restaurant unternimmt alle erforderlichen Schritte, um sicherzustellen, dass die Informationen
                    in Bezug auf die Online-Bestellung richtig und zuverlässig sind. Dies kann jedoch nicht unfehlbar
                    sein und manchmal können Fehler auftreten. Sie sollten geeignete Schritte unternehmen, um alle
                    Informationen im Zusammenhang mit der Online-Bestellung zu überprüfen, bevor Sie sie verwenden.
                    Soweit dies nach geltendem Recht zulässig ist, lehnt das Restaurant jegliche ausdrückliche oder
                    stillschweigende Gewährleistung oder Zusicherung jeglicher Art in Bezug auf die Online-Bestellung
                    ab, einschließlich, jedoch nicht beschränkt auf die Verfügbarkeit der Online-Bestellungs-App.
                </p>
                <p>
                    Das Restaurant kann von Zeit zu Zeit die Informationen in Bezug auf die Online-Bestell-App und /
                    oder den Bestellvorgang überarbeiten und behält sich das Recht vor, solche Änderungen vorzunehmen,
                    ohne verpflichtet zu sein, frühere, aktuelle oder potenzielle Kunden zu benachrichtigen. In keinem
                    Fall haftet das Restaurant für indirekte, besondere, zufällige oder Folgeschäden, die sich aus der
                    Verwendung der hierin enthaltenen Informationen und / oder im Zusammenhang mit dem
                    Online-Bestellvorgang ergeben.
                </p>
                <p>
                    Durch die Online-Bestellung erkennen Sie an und erklären sich damit einverstanden, dass die Nutzung
                    der Online-Bestell-App und / oder der Bestellvorgänge auf eigenes Risiko geschieht und dass Wir in
                    dem nach geltendem Recht maximal zulässigen Umfang unter keinen Umständen für direkte, indirekte
                    oder zufällige Ereignisse haften, für Sonder-, Folge- oder Strafschadenersatz, Verluste, Ausgaben
                    oder Aufwendungen sowie für entgangenen Gewinn, der sich aus der Nutzung oder Unfähigkeit zur
                    Nutzung dieser Online-Bestellung und / oder einer App und / oder eines Materials auf einer mit
                    dieser Online-Website verknüpften Website ergibt (einschließlich, aber nicht beschränkt auf Viren
                    oder andere Fehler oder Defekte oder Fehler bei der Rechnerübertragung oder Netzwerkkommunikation),
                    auch wenn Wir auf die Möglichkeit eines solchen Schadens hingewiesen wurden. Darüber hinaus können
                    Wir keine Haftung für Änderungen übernehmen, die von nicht autorisierten Dritten am Inhalt der
                    Online-Bestell-App und / oder des Bestellvorganges vorgenommen wurden. Alle ausdrücklichen oder
                    stillschweigenden Garantien oder Zusicherungen sind im maximal nach geltendem Recht zulässigen
                    Umfang ausgeschlossen.
                </p>
                <p>
                    Die Online-Bestell-App und / oder der Online-Bestellprozess können Inhalte, Informationen oder Links
                    zu dritten Parteien oder der Websites Dritter enthalten. Das Restaurant haftet nicht für den Inhalt
                    solcher Websites und auch nicht für den Inhalt von Werbung oder Sponsoring Dritter oder für die
                    Einhaltung solcher Vorschriften. Der Zugriff auf die Links erfolgt auf eigenes Risiko des Benutzers.
                    Das Restaurant gibt keine Zusicherungen oder Gewährleistungen hinsichtlich des Inhalts, der
                    Vollständigkeit oder Richtigkeit dieser Links oder der Websites ab, die mit dieser Online-
                    Bestellungs-App verlinkt sind. Sie erklären sich damit einverstanden, das Restaurant von jeglicher
                    Haftung freizustellen, die sich aus der Verwendung von Informationen Dritter oder der Nutzung von
                    Websites Dritter ergibt.
                </p>
                <p>
                    Sofern nicht ausdrücklich anders angegeben, sind alle Informationen in Bezug auf die
                    Online-Bestell-App (einschließlich, jedoch nicht beschränkt auf die Bilder, Schaltflächen und Texte)
                    Eigentum des Lizenzgebers und / oder können mit Genehmigung des Lizenzgebers der Lizenzvereinbarung
                    in Bezug auf die Verwendung der App für Onlinebestellungen verwendet werden und darf ohne vorherige
                    schriftliche Genehmigung in keiner Form und in keiner Weise elektronisch, mechanisch, fotokopiert,
                    aufgezeichnet oder auf andere Weise kopiert, verteilt oder reproduziert oder übertragen werden.
                </p>
                <p>
                    Der Inhalt, der sich auf bestimmte Produkte (z. B. Lebensmittel), die Anordnung und das Textlayout
                    der Online-Bestell-App und / oder des Bestellvorganges, die Marken und sonstige Inhalte bezieht, ist
                    urheberrechtlich und gemäß den geltenden gesetzlichen Bestimmungen geschützt und kann in keiner
                    Weise ohne die ausdrückliche schriftliche Genehmigung des Restaurants, verwendet werden.<br></br>{' '}
                    Der Kunde erhält keine Lizenz oder Rechte in Bezug auf die Informationen im Zusammenhang mit der
                    Online-Bestellung und / oder -App.
                </p>
                <p>
                    Wenn Sie sich entscheiden, online über die Online-Bestell-App zu bestellen, werden Sie
                    möglicherweise aufgefordert, die vollständigen Kontaktdaten anzugeben und / oder ein Konto zu
                    erstellen, und Sie müssen möglicherweise Cookies akzeptieren. Sie müssen Ihre Daten vertraulich
                    behandeln und dürfen sie nicht an Dritte weitergeben. Das Restaurant behält sich das Recht vor, die
                    Nutzung der Online-Bestell-App und / oder des Bestellvorganges auszusetzen, wenn Sie gegen die
                    Allgemeinen Geschäftsbedingungen verstoßen.
                </p>
                <p>
                    Sie nehmen zur Kenntnis und stimmen zu, dass alle Bestellungen als ausdrückliche Absicht behandelt
                    werden, die bezeichneten Produkte und / oder Dienstleistungen zu den vereinbarten Online-Preisen zu
                    erwerben. Wir behandeln dies als verbindliches Angebot von Ihnen, solche Produkte und
                    Dienstleistungen zu erwerben. Änderungen müssen schriftlich erfolgen, da sie sonst für keine Partei
                    bindend sind.
                </p>
                <p>
                    Die Annahme einer Bestellung für Produkte und / oder Dienstleistungen liegt im alleinigen Ermessen
                    des Restaurants. Unsere Annahme einer Bestellung erfolgt, wenn Sie eine Bildschirmnachricht und /
                    oder eine E-Mail-Benachrichtigung und / oder eine SMS erhalten, die Ihre Bestellung bestätigt.
                </p>
                <p>
                    Das Restaurant behält sich das Recht vor, Dienstleistungen abzulehnen, den Zugriff auf die
                    Online-Bestell-App zu beenden und / oder Inhalte zu bearbeiten, zu entfernen oder zu bearbeiten oder
                    Ihre Bestellung (en) nach eigenem Ermessen und ohne vorherige Ankündigung anzunehmen.<br></br> Die
                    Online-Bestell-App des Restaurants darf nur von Personen über achtzehn (18) Jahren oder dem
                    gesetzlichen Mindestalter oder auf andere Weise unter Aufsicht eines Erwachsenen oder
                    Erziehungsberechtigten verwendet werden.
                </p>
                <p>
                    Alle Produkte und / oder Dienstleistungen, die über die Online-Bestell-App bereitgestellt werden,
                    erfolgen im &quot;Istzustand&quot; und &quot;aufgrund der Verfügbarkeit&quot;. Das Restaurant
                    schließt Garantien, Bedingungen, Zusicherungen oder andere Bedingungen in Bezug auf die
                    Online-Bestellung oder den Inhalt der darauf angezeigte Produkte ausdrücklich aus, ob ausgedrückt
                    oder stillschweigend, sofern nicht anders angegeben.
                </p>
                <p>
                    Die Abbildungen der Produkte dienen nur zur Präsentation. Die bestellten Produkte können
                    Unterschiede (z. B. Farbe, Form usw.) zu den auf der Website vorhandenen Fotos aufweisen. Das
                    Restaurant haftet in keiner Weise, wenn die Beschreibung der Produkte nicht vollständig ist.
                </p>
                <p>
                    Lieferaufträge unterliegen außerdem: i. Ihrer Adresse, die in den definierten Lieferbereich des
                    Restaurants sein muss; ii. Verfügbarkeit des Online-Restaurants zur Annahme von Online-Bestellungen;
                    iii. Ihre Bestellung kann einem Mindestbetrag pro Bestellung unterliegen;<br></br> Sie können mit
                    einer der in unserem Checkout-Bildschirm aufgeführten Methoden bezahlen. Bitte stellen Sie sicher,
                    dass die benutzte Bankkarte zum Zeitpunkt Ihrer Bestellung gültig ist, wenn Ihre Bestellung mit
                    einer Kredit- oder Debitkarte aufgegeben wird. Das Restaurant kann keine Rückerstattung für die
                    online bezahlten Bestellungen gewähren. Kontaktieren Sie uns direkt, um Zahlungsstreitigkeiten oder
                    Rückerstattungsansprüche beizulegen.
                </p>
                <p>
                    Möglicherweise werden Sie automatisch zu einer Online-Liste weitergeleitet, die sich auf den
                    Service-Standort des Restaurants in Ihrer Nähe bezieht. Bitte beachten Sie, dass die Preise,
                    Mindestausgabenbeschränkungen und Höchstausgabenbeschränkungen von Ort zu Ort verschieden sind. Wenn
                    Sie online bestellen, kann der berechnete Preis außerdem vom Preis für die Produkte abweichen, wenn
                    diese im Geschäft oder telefonisch bestellt wurden.
                </p>
                <p>
                    Die einmal aufgegebene Online-Bestellung kann weder über die Website noch offline, indem Sie das
                    Restaurant anrufen, geändert oder storniert werden. Jedenfalls, wenn Sie Ihre Bestellung stornieren
                    oder sich über diese beschweren möchten, wenden Sie sich bitte an Ihren örtlichen
                    Restaurant-Service-Standort. Einzelheiten dazu finden Sie in der Bestätigungs-E-Mail, die Sie bei
                    Ihrer Bestellung erhalten haben. Wir werden prüfen, wie wir Ihnen helfen können.
                </p>
                <p>
                    Wir werden uns bemühen, Ihnen Ihre bestellten Produkte so nahe wie möglich an Ihrer gewünschten
                    Liefer- / Abholzeit zur Verfügung zu stellen, können jedoch die Lieferzeit nicht in allen Fällen
                    garantieren. Die Lieferzeit kann aufgrund von schlechtem Wetter oder Verkehrsbedingungen
                    beeinträchtigt werden. Wir wollen die Sicherheit unserer Fahrer gewährleisten. Der Lieferservice ist
                    in ausgewählten Gebieten aufgrund schlechten Wetters oder unvorhergesehener Umstände möglicherweise
                    vorübergehend nicht verfügbar.
                </p>
                <p>
                    Der Kunde verpflichtet sich, die Lieferung der Produkte zum vereinbarten Zeitpunkt und Ort der
                    Lieferung anzunehmen. Wenn Sie sich für die Lieferung der Produkte entschieden haben, liefert das
                    Restaurant die Bestellung an den Haupteingang der Lieferadresse. Lieferungen an die Lieferadresse
                    werden jedoch nur dann hineingetragen, wenn sowohl der Fahrer als auch Sie dem zustimmen. Wenn Sie
                    nicht anwesend sind, um die Waren an der in Ihrer Bestellung angegebenen Adresse entgegenzunehmen,
                    erstatten wir Ihnen den Preis für Ihre Bestellung nicht zurück und berechnen Ihnen den vollen Betrag
                    Ihrer Bestellung.
                </p>
                <p>
                    Das Risiko für die Produkte geht mit der Lieferung auf den Kunden über. Jeder Software-Service /
                    jede Software wird auf eigenes Risiko verwendet und / oder heruntergeladen. Wenn Sie Zweifel an der
                    Eignung des Software-Dienstes / der Software haben, die für Ihren Rechner verwendet und / oder
                    heruntergeladen werden soll, wird empfohlen, dass Sie sich vor der Verwendung und / oder dem
                    Herunterladen beraten lassen.
                </p>
                <p>
                    Sie sind für die Wahrung der Vertraulichkeit Ihrer persönlichen Daten, Ihres Passworts und Ihrer
                    Zahlungsdaten verantwortlich. Sie erklären sich damit einverstanden, die volle Verantwortung für
                    alle Ihre Handlungen im Zusammenhang mit der Online-Bestellung (Bewerbung) zu übernehmen.
                </p>
                <p>
                    Sie dürfen Ihre Rechte unter diesen Geschäftsbedingungen nicht abtreten, unterlizenzieren oder
                    anderweitig übertragen. Sollte eine Bestimmung dieser Vereinbarung nichtig, rechtswidrig, ungültig
                    oder nicht anwendbar sein und / oder werden, so berührt dies nicht die Gültigkeit oder Anwendbarkeit
                    der anderen Vertragsklauseln, die weiterhin in Kraft bleiben und rechtliche Auswirkungen haben, als
                    ob die eine nichtige, rechtswidrige, ungültige oder nicht anwendbare Klausel nicht Teil dieser
                    Vereinbarung wäre.
                </p>
                <p>Diese Allgemeinen Geschäftsbedingungen berühren nicht Ihre gesetzlichen Rechte.</p>
                <p>
                    Die Restaurantmarken sowie die zugehörigen Marken anderer und zugehöriges Eigentum sind nach
                    nationalem und internationalem Recht vor dem Kopieren und Simulieren geschützt und dürfen ohne die
                    ausdrückliche schriftliche Genehmigung des Restaurants nicht wiedergegeben oder kopiert werden.
                </p>
                <p>
                    Soweit gesetzlich zulässig, schließt das Restaurant jegliche Haftung aus, die sich aus der Lieferung
                    der Produkte ergibt, und haftet insbesondere nicht für Verluste oder Schäden, die direkt oder
                    indirekt aus oder im Zusammenhang mit Verzögerungen entstehen, die über die geschätzte Lieferungs-
                    oder Abholzeit hinausgehen; Umstände, über dien das Restaurant keine Kontrolle über die Folgen hatte
                    und die das Restaurant durch angemessene Sorgfalt nicht vermeiden konnte, oder indirekte oder
                    unvorhersehbare Verluste, die dem Kunden oder anderen entstehen. In jedem Fall darf die Haftung des
                    Restaurants gegenüber dem Kunden den für die jeweiligen Produkte / und / oder Dienstleistungen
                    berechneten Gesamtpreis nicht überschreiten.
                </p>
                <p>
                    Diese Allgemeinen Geschäftsbedingungen unterliegen den Gesetzen des Landes, in dem sich der Sitz des
                    Restaurants und das Restaurant befinden, und werden in Übereinstimmung mit diesen ausgelegt. Alle
                    Streitigkeiten, die sich aus oder im Zusammenhang mit diesen ergeben, werden von den zuständigen
                    Gerichten des Landes für den Hauptsitz des Restaurants beigelegt, unter Ausschluss der Möglichkeit
                    der Bezugnahme auf Kollisionsnormen.
                </p>
            </div>
        </div>
    );
};

export default Agb;
