import React from 'react';

import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';

interface ConfirmationDialogProps {
    open: boolean;
    handleClose: (ok: boolean) => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, handleClose }) => {
    return (
        <Dialog open={open}>
            <DialogTitle>Bezahlung abbrechen?</DialogTitle>
            <DialogContent>
                <DialogContentText>Du bist dabei die Bezahlung abzubrechen. Bist du dir sicher?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose(false);
                    }}
                >
                    Nein
                </Button>
                <Button
                    onClick={() => {
                        handleClose(true);
                    }}
                    autoFocus
                >
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    );
};
