import { MenuGroup } from '@bestelleck/utils';

import { CustomError } from './errors';

export const handleErrors = (response: Response): Response => {
    if (!response.ok) {
        throw new CustomError(response.statusText, response.status);
    }
    return response;
};

export const handleErrorsJson = async (response: Response): Promise<Response> => {
    if (!response.ok) {
        const json = await response.json();
        throw new CustomError(json.message, response.status);
    }
    return response;
};

export const isGroupAvailable = (group: MenuGroup): boolean => {
    if (!group.availableSchedule) {
        return true;
    }
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
    const today = group.availableSchedule.find((schedule) => schedule.dayOfWeek === currentDay);
    if (!today) {
        return false;
    }
    const isAvailableNow = today.frames.some((frame) => currentTime > frame.from && currentTime < frame.to);
    return isAvailableNow;
};
