import { GeoInfo, GeoLocation, Location } from '../types/Geo';
import { baseUrlV2 } from '../util/constants';

export const isPostalCode = (value: string): boolean => {
    return /^([0-9]{5})$/.test(value);
};

export const getGeoInformation = async (postalCode: string): Promise<GeoInfo> => {
    if (postalCode === '') {
        return {
            cities: [],
            notFound: false,
        };
    }
    if (isPostalCode(postalCode)) {
        const url = `${baseUrlV2}/geo/spatial?postalcode=${postalCode}`;
        const response = await fetch(url);
        if (!response.ok) {
            return {
                cities: [],
                notFound: true,
            };
        }
        const geo = await response.json();
        return {
            cities: geo,
            notFound: false,
        };
    }
    return {
        cities: [],
        notFound: true,
    };
};

export const reverseGeoCode = async ({ latitude, longitude }: GeoLocation): Promise<Location[]> => {
    if (latitude === '') {
        return [];
    }

    const url = `${baseUrlV2}/geo/location?latitude=${latitude}&longitude=${longitude}`;
    const response = await fetch(url);
    if (!response.ok) {
        return [];
    }
    return response.json();
};
