export enum Additives {
    Add_1 = "mit Farbstoff",
    Add_2 = "mit Konservierungsstoffen",
    Add_3 = "mit Antioxidationsmitteln",
    Add_4 = "mit Geschmacksverstärker",
    Add_5 = "geschwefelt",
    Add_6 = "geschwärzt",
    Add_7 = "gewachst",
    Add_8 = "mit Süßstoff",
    Add_8a = "mit Süßstoff / bei Aspartam zudem: enthält eine Phenylalaninquelle",
    Add_9 = "mit Phosphat",
    Add_10 = "mit Nitritpökelsalz",
    Add_10a = "mit Milcheiweiß",
    Add_10b = "mit Eiklar",
    Add_10c = "mit Sahne",
    Add_11 = "koffeinhaltig",
    Add_11a = "chininhaltig",
    Add_11b = "mit Taurin",
    Add_12a = "mit Säuerungsmittel",
    Add_12b = "enthält Stabilisatoren",
    Add_13 = "Trennmittel",
}
