import { Additives } from "./additives.enum";

export function getAdditives(additiveCodes: string[]): string[] {
    const result: Additives[] = [];
    for (let code of additiveCodes) {
        code = `Add_${code}`;
        const additive = Additives[code as keyof typeof Additives];
        if (!additive) continue;
        result.push(additive);
    }
    return result;
}

export function getAdditivesFromKey(additiveKeys: string[]): string[] {
    const result: string[] = [];
    for (const key of additiveKeys) {
        if (key.includes("Add_")) {
            const code = key.substring(4);
            result.push(code);
        } else {
            result.push(key);
        }
    }
    return result;
}
