import React from 'react';

import { OrderType, RestaurantDetail } from '@bestelleck/utils';
import { Select } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '../../../../store/store';

import styles from './DeliverySelect.module.scss';

interface DeliverySelectComponentProps {
    restaurant: RestaurantDetail | undefined | null;
}

export const DeliverySelectComponent: React.FC<DeliverySelectComponentProps> = ({ restaurant }) => {
    const dispatch = useDispatch<Dispatch>();
    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);

    return (
        <div className={styles.root}>
            <h3 style={{ marginBottom: 10 }}>Bestellart</h3>
            <Select
                native
                variant="outlined"
                className={styles.half}
                value={orderType}
                color="secondary"
                onChange={(event) => {
                    dispatch.orderType.setOrderType({ orderType: event.target.value as OrderType });
                }}
            >
                <option value={'pickup'}>Abholung</option>
                {restaurant?.delivery && <option value={'delivery'}>Lieferung</option>}
            </Select>
        </div>
    );
};
