export enum Allergens {
    A = "Glutenhaltiges Getreide und daraus gewonnene Erzeugnisse",
    A1 = "Weizen",
    A2 = "Roggen",
    A3 = "Gerste",
    A4 = "Hafer",
    A5 = "Dinkel",
    A6 = "Kamut ",
    B = "Krebstiere und daraus gewonnene Erzeugnisse",
    C = "Eier und daraus gewonnene Erzeugnisse",
    D = "Fische und daraus gewonnene Erzeugnisse",
    E = "Erdnüsse und daraus gewonnene Erzeugnisse",
    F = "Sojabohnen und daraus gewonnene Erzeugnisse",
    G = "Milch und daraus gewonnene Erzeugnisse (einschließlich Laktose)",
    H = "Schalenfrüchte und daraus gewonnene Erzeugnisse",
    H1 = "Mandeln",
    H2 = "Haselnüsse",
    H3 = "Walnüsse",
    H4 = "Kaschunüsse",
    H5 = "Pecannüsse",
    H6 = "Paranüsse",
    H7 = "Pistazien",
    H8 = "Macadamianüsse",
    H9 = "Queenslandnüsse",
    H10 = "Pinienkerne",
    I = "Sellerie und daraus gewonnene Erzeugnisse",
    J = "Senf und daraus gewonnene Erzeugnisse",
    K = "Sesamsamen und daraus gewonnene Erzeugnisse",
    L = "Schwefeldioxid und Sulfite",
    M = "Lupine und daraus gewonnene Erzeugnisse",
    N = "Weichtiere und daraus gewonnene Erzeugnisse",
}
