import {
    convertDateToTime,
    Coupon,
    CouponType,
    getOpeningHoursForDate,
    isRestaurantOpenOnDate,
    MenuGroup,
    MenuItem,
    OrderType,
    Restaurant,
} from '@bestelleck/utils';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { CartItem, ItemOption } from '../../../webapp/src/redux/cart/types';
import { MappedExtraGroup } from '../../../webapp/src/screens/Restaurant/MenuItem/MenuItem.utils';
import { City } from '../../../webapp/src/types/Geo';

export class CustomError extends Error {
    public code: number;
    constructor(message = 'Something went wrong', code = 500) {
        super();
        this.message = message;
        this.code = code;
    }
}

export function dayOfWeekAsString(dayIndex: number): string {
    return ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][dayIndex] || '';
}

export const handleErrors = (response: Response): Response => {
    if (!response.ok) {
        throw new CustomError(response.statusText, response.status);
    }
    return response;
};

export const handleErrorsJson = async (response: Response): Promise<Response> => {
    if (!response.ok) {
        const json = await response.json();
        throw new CustomError(json.message, response.status);
    }
    return response;
};

type PriceParameters = {
    items: readonly CartItem[];
    orderType: OrderType;
    deliveryCost: number;
    menuCoupon?: Pick<Coupon, 'id' | 'minimumOrderValue' | 'modifier'>;
    coupon?: Coupon;
    feeThreshold?: number;
};

export function calculatePrice({ items, orderType, deliveryCost, menuCoupon, coupon, feeThreshold }: PriceParameters): {
    price: number;
    discount: number;
    couponDiscount: number;
    subtotal: number;
    deliveryFee: number;
} {
    let price = 0;
    let discount = 0;
    let couponDiscount = 0;
    let deliveryFee = deliveryCost;
    const isDelivery = orderType === OrderType.Delivery;
    items.forEach((item) => {
        price = roundNumberTwoDecimals(price + calculatePriceForItem(item, isDelivery));
    });
    const subtotal = price;
    if (menuCoupon) {
        if (menuCoupon.modifier.type === CouponType.Percentage) {
            discount = roundNumberTwoDecimals((price / 100) * menuCoupon.modifier.value);
            price -= discount;
        } else if (menuCoupon.modifier.type === CouponType.Fixed) {
            discount = menuCoupon.modifier.value;
            price -= discount;
        }
    }
    if (coupon) {
        if (coupon.modifier.type === CouponType.Percentage) {
            couponDiscount = roundNumberTwoDecimals((price / 100) * coupon.modifier.value);
            price -= couponDiscount;
        } else if (coupon.modifier.type === CouponType.Fixed) {
            couponDiscount = coupon.modifier.value;
            price -= couponDiscount;
        }
    }
    if (isDelivery && deliveryCost) {
        if (feeThreshold && price >= feeThreshold) {
            deliveryFee = 0;
        } else {
            price += deliveryCost;
        }
    }
    return { price, discount, subtotal, deliveryFee, couponDiscount };
}

export function calculatePriceForItem(item: CartItem, isDelivery: boolean): number {
    let itemPrice = 0;

    const basePrice = (isDelivery ? item.basePrice.delivery : item.basePrice.pickup) as number;
    item.options.forEach((option) => {
        if (option) {
            if (isDelivery && option?.selectedValue.priceModifier.delivery !== undefined) {
                itemPrice += option.selectedValue.priceModifier.delivery;
            } else if (!isDelivery && option?.selectedValue.priceModifier.pickup !== undefined) {
                itemPrice += option.selectedValue.priceModifier.pickup;
            }
        }
    });

    item.extraGroups.forEach((extraGroup) => {
        extraGroup.extras.forEach((extra) => {
            const extraBasePrice = isDelivery ? extra.basePrice.delivery : extra.basePrice.pickup;
            itemPrice += extraBasePrice as number;
        });
    });
    itemPrice += basePrice;
    return itemPrice * item.amount;
}

export const calculatePriceForMenuItem = (
    menuItem: MenuItem,
    selectedExtras: MappedExtraGroup[],
    selectedOptions: ItemOption[],
    isDelivery: boolean,
    amount: number,
): number => {
    const flatExtras = selectedExtras.map((selected) => selected.extras).reduce((a, b) => a.concat(b), []);
    const filteredExtras = flatExtras.filter((extra) => extra.selected === true);
    let itemPrice = 0;

    selectedOptions.forEach((option) => {
        if (option !== null) {
            if (isDelivery && option?.selectedValue.priceModifier.delivery !== undefined) {
                itemPrice += option.selectedValue.priceModifier.delivery;
            } else if (!isDelivery && option?.selectedValue.priceModifier.pickup !== undefined) {
                itemPrice += option.selectedValue.priceModifier.pickup;
            }
        }
    });

    filteredExtras.forEach((extra) => {
        const extraBasePrice = (isDelivery ? extra.basePrice.delivery : extra.basePrice.pickup) as number;
        itemPrice += extraBasePrice;
    });
    itemPrice += (isDelivery ? menuItem.basePrice.delivery : menuItem.basePrice.pickup) as number;
    return roundNumberTwoDecimals(itemPrice * amount);
};

export function calculateCoupon(price: number, coupon: Coupon) {
    let discount = 0;
    if (coupon.modifier.type === CouponType.Percentage) {
        discount = roundNumberTwoDecimals((price / 100) * coupon.modifier.value);
        price -= discount;
    }
    if (coupon.modifier.type === CouponType.Fixed) {
        discount = coupon.modifier.value;
        price -= discount;
    }
    return { price, discount };
}

export function getCouponTypeString(type: string) {
    return type === CouponType.Fixed ? '€' : '%';
}

export function formatPrice(price: number): string {
    const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });
    return formatter.format(price);
}

export function formatNumber(num: number, decimals = 2): string {
    return new Intl.NumberFormat('de-DE', {
        maximumSignificantDigits: decimals,
    }).format(num);
}

export const prettyTime = (time: number): string => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const calculateDistance = (
    a: Omit<City, 'city' | 'postalCode' | 'id' | 'adressName'>,
    b: Omit<City, 'city' | 'postalCode' | 'id' | 'adressName'>,
): number => {
    const lat = ((Number(a.latitude) + Number(b.latitude)) / 2) * 0.01745;
    const dx = 111.3 * Math.cos(lat) * (Number(b.longitude) - Number(a.longitude));
    const dy = 111.3 * (Number(b.latitude) - Number(a.latitude));
    return Math.sqrt(dx * dx + dy * dy);
};

export const notUndefined = (anyValue: unknown): boolean => typeof anyValue !== 'undefined';

export const getUniqueKey = (): string => {
    return uuidv4();
};

export const calculateIsOpen = (restaurant: Restaurant, orderType: OrderType): boolean => {
    const hasDelivery = restaurant.delivery ? true : false;
    if (orderType === OrderType.Delivery && hasDelivery) {
        return isRestaurantOpenOnDate(restaurant.delivery.days, restaurant.delivery.exceptions);
    } else if (orderType === OrderType.PickUp) {
        return isRestaurantOpenOnDate(restaurant.pickup.days, restaurant.pickup.exceptions);
    }
    return true;
};

export const isGroupAvailable = (group: MenuGroup): boolean => {
    if (!group.availableSchedule) {
        return true;
    }
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
    const today = group.availableSchedule.find((schedule) => schedule.dayOfWeek === currentDay);
    if (!today) {
        return false;
    }
    const isAvailableNow = today.frames.some((frame) => currentTime > frame.from && currentTime < frame.to);
    return isAvailableNow;
};

export const customFetch = async (input: RequestInfo, init?: RequestInit | undefined): Promise<any> => {
    const response = await fetch(input, init);
    if (!response.ok) {
        const body = await response.json();
        if (body && body.message) {
            throw new CustomError(body.message, response.status);
        }
        throw new CustomError(response.statusText, response.status);
    }
    return response.json();
};

export const isObject = (obj: unknown): boolean => {
    if (typeof obj === 'object' && obj != null) {
        return true;
    } else {
        return false;
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) {
        return true;
    } else if (isObject(obj1) && isObject(obj2)) {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) {
            return false;
        }
        for (const prop in obj1) {
            if (!deepEqual(obj1[prop], obj2[prop])) {
                return false;
            }
        }
        return true;
    }
    return false;
};

export const roundNumberTwoDecimals = (x: number): number => {
    return Math.round((x + Number.EPSILON) * 100) / 100;
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export const isAfter9am = moment().isAfter(moment('09:00:00', 'HH:mm:ss'));

export const isPreOrderCurrentlyPossible = (
    restaurant: Restaurant | undefined | null,
    isDelivery: boolean,
    isOpen: boolean,
): boolean => {
    if (!restaurant) return false;
    const preOrderPossible =
        isDelivery && restaurant.delivery ? restaurant.delivery.supportsPreOrder : restaurant.pickup.supportsPreOrder;
    const openingSchedule = isDelivery && restaurant.delivery ? restaurant.delivery : restaurant.pickup;
    const currentDate = new Date();

    const currentTime = convertDateToTime(currentDate);
    const nextOpeningFrame = getOpeningHoursForDate(
        openingSchedule.days,
        openingSchedule.exceptions,
        currentDate,
    )?.find((frame) => frame.from > currentTime);
    const nextOpeningFrameExistsToday = nextOpeningFrame ? true : false;
    return !isOpen && nextOpeningFrameExistsToday && isAfter9am && preOrderPossible;
};

export const useQuery = (): URLSearchParams => {
    return new URLSearchParams(useLocation().search);
};
