import React from 'react';

import './App.css';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { getPersistor } from '@rematch/persist';
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { LoadRestaurantComponent } from './components/LoadRestaurant/LoadRestaurant';
import { CheckPaymentComponent } from './components/Stripe/checkApplePay';
import Agb from './screens/Agb/Agb';
import CheckoutComponent from './screens/Checkout/Checkout';
import CompletedScreen from './screens/Completed/Completed';
import { Contact } from './screens/Contact/Contact';
import Datenschutz from './screens/Datenschutz/Datenschutz';
import { EntryScreen } from './screens/Entry/Entry';
import ErrorScreen from './screens/Error/ErrorScreen';
import { Impressum } from './screens/Impressum/Impressum';
import { MenuComponent } from './screens/Menu/Menu';
import { store } from './store/store';
import { version } from './util/constants';
import ScrollToTop from './util/scroll/scrollToTop';

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#1e3446',
            },
            secondary: {
                main: '#26547c',
            },
        },
        typography: {
            fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
        },
    });

    const stripeKey = process.env.REACT_APP_STRIPE;
    if (!stripeKey) {
        return <></>;
    }
    const stripePromise = loadStripe(stripeKey);
    const persistor = getPersistor();

    Sentry.init({
        dsn: 'https://f7cb9df112f4d43fe70b18059a4e7b4c@o1226834.ingest.us.sentry.io/4508167686979584',
        integrations: [Sentry.browserTracingIntegration()],
        enabled: process.env.REACT_APP_ENV === 'production',
        // Performance Monitoring
        tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production
        release: version,
    });

    return (
        <div className="App">
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Elements stripe={stripePromise}>
                        <PersistGate persistor={persistor}>
                            <Provider store={store}>
                                <CheckPaymentComponent />
                                <LoadRestaurantComponent />
                                <Router>
                                    <ScrollToTop />
                                    <Header></Header>
                                    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
                                        <Switch>
                                            <Route path="/agb">
                                                <Agb />
                                            </Route>
                                            <Route path="/impressum">
                                                <Impressum />
                                            </Route>
                                            <Route path="/datenschutz">
                                                <Datenschutz />
                                            </Route>
                                            <Route path="/menu">
                                                <MenuComponent />
                                            </Route>
                                            <Route path="/kontakt">
                                                <Contact />
                                            </Route>
                                            <Route path="/checkout">
                                                <CheckoutComponent />
                                            </Route>
                                            <Route path="/completed">
                                                <CompletedScreen />
                                            </Route>
                                            <Route path="/">
                                                <EntryScreen />
                                            </Route>
                                        </Switch>
                                    </Sentry.ErrorBoundary>
                                    <Footer />
                                </Router>
                            </Provider>
                        </PersistGate>
                    </Elements>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
}

export default App;
