import React from 'react';

import { Phone, Place } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@mui/material';
import { FaCoins, FaGooglePay, FaRegCreditCard } from 'react-icons/fa';
import { SiApplepay } from 'react-icons/si';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './Footer.css';

import { RootState } from '../../store/store';
import { isHunger } from '../../util/constants';
import { getUniqueKey } from '../../util/utils';

import styles from './Footer.module.scss';

export const Footer: React.FC = () => {
    const storeRestaurant = useSelector((state: RootState) => state.restaurant.restaurant);

    return (
        <div className={styles.footer}>
            <div className={styles.Footer}>
                <Card className={styles.card}>
                    <CardContent className={styles.cardContent}>
                        <Typography variant="h6">Kontakt</Typography>
                        <div className={styles.container}>
                            <Place />
                            <div className={styles.text}>
                                <Typography>{storeRestaurant?.contact.street}</Typography>
                                <Typography>
                                    {storeRestaurant?.contact.postalCode} {storeRestaurant?.contact.city}
                                </Typography>
                            </div>
                        </div>
                        <div className={styles.container}>
                            <Phone />
                            <Typography className={styles.text}>{storeRestaurant?.contact.phoneNumber}</Typography>
                        </div>
                    </CardContent>
                </Card>
                <Card className={styles.card}>
                    <CardContent className={styles.cardContent}>
                        <Typography variant="h6">Links</Typography>
                        <Link to="/impressum">
                            <Typography>Impressum</Typography>
                        </Link>
                        <Typography>
                            <Link to="/datenschutz">Datenschutz</Link>
                        </Typography>
                        <Typography>
                            <Link to="/agb">AGB</Link>
                        </Typography>
                        <Typography>
                            <Link to="/kontakt">Kontakt</Link>
                        </Typography>
                    </CardContent>
                </Card>
            </div>
            <div className={styles.payment}>
                <div className={styles.paymentHeader}>Akzeptierte Zahlungsmethoden</div>
                {storeRestaurant?.payment.paymentOptions.map((payment) => {
                    if (payment === 'cash') {
                        return <FaCoins key={getUniqueKey()} />;
                    }
                    if (payment === 'paypal') {
                        return <img key={getUniqueKey()} alt="paypal" src={`assets/paypal.png`} />;
                    }
                    if (payment === 'card') {
                        return <FaRegCreditCard color="black" key={getUniqueKey()} />;
                    }
                    if (payment === 'applepay') {
                        return (
                            <SiApplepay
                                style={{ height: 40, width: 40, marginBottom: 0, padding: 6 }}
                                color="black"
                                key={getUniqueKey()}
                            />
                        );
                    }
                    if (payment === 'googlepay') {
                        return <FaGooglePay color="black" key={getUniqueKey()} />;
                    }
                    if (payment === 'giropay') {
                        return (
                            <img
                                className={styles.giro}
                                key={getUniqueKey()}
                                alt="giropay"
                                src={`assets/giropay.svg`}
                            />
                        );
                    }
                    return null;
                })}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>Partner von:</Typography>
                {isHunger && (
                    <a href="https://hunger-hunger.de" target="_blank" rel="noreferrer">
                        <img className={styles.logo} src="./hunger-hunger-logo-text.png" alt="hunger-hunger Logo" />
                    </a>
                )}
                {!isHunger && (
                    <a href="https://bestelleck.de" target="_blank" rel="noreferrer" className={styles.bestelleck}>
                        <img className={styles.logoBE} src="./logo512BestellEck.png" alt="BestellEck Logo" />
                        <span>bestelleck.de</span>
                    </a>
                )}
            </div>
        </div>
    );
};
