import React from 'react';

import { Close } from '@mui/icons-material';
import { DialogTitle as MuiDialogTitle, IconButton } from '@mui/material';

import styles from './DialogTitle.module.scss';

export interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    colorScheme?: 'primary' | 'secondary';
    onClose: (event: any) => void;
}

export const DialogTitle: React.FC<DialogTitleProps> = (props: DialogTitleProps) => {
    const { children, onClose, colorScheme, ...other } = props;
    const isPrimaryColor = !colorScheme || colorScheme === 'primary';

    return (
        <MuiDialogTitle className={isPrimaryColor ? styles.rootPrimary : styles.rootSecondary} {...other}>
            <div className={styles.placeholder}></div>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={isPrimaryColor ? styles.closeButton : ''}
                    onClick={onClose}
                    size="large"
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};
