import React from 'react';

import { MenuItem } from '@bestelleck/utils';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import { Dispatch } from '../../../../../store/store';
import { CartItem, ItemOption } from '../../../../../store/types/cart.types';
import { formatPrice } from '../../../../../util/price';
import { calculatePriceForMenuItem } from '../../../../../util/utils';
import { MappedExtraGroup } from '../../MenuItem.utils';
import { ItemQuantityComponent } from '../ItemQuantity/ItemQuantity';

import styles from './MenuItemFooter.module.scss';

type MenuItemFooterProps = {
    restaurantId: string;
    handleClose: () => void;
    item: MenuItem;
    isDelivery: boolean;
    isAvailable: boolean;
    selectedExtras: MappedExtraGroup[];
    selectedOptions: ItemOption[];
    initialSelectedExtras: MappedExtraGroup[];
    setExtras: (extras: MappedExtraGroup[]) => void;
    note: string;
};

export const MenuItemFooter: React.FC<MenuItemFooterProps> = ({
    item,
    handleClose,
    isDelivery,
    selectedExtras,
    selectedOptions,
    initialSelectedExtras,
    setExtras,
    isAvailable,
    note,
}) => {
    const dispatch = useDispatch<Dispatch>();
    const [amount, setAmount] = React.useState(1);

    const addToCart = (
        { name, id, basePrice }: MenuItem,
        selectedExtras: MappedExtraGroup[] = [],
        selectedOptions: ItemOption[] = [],
    ) => {
        const extraGroups = selectedExtras.map((extraGroup) => {
            return {
                name: extraGroup.name,
                id: extraGroup.id,
                extras: extraGroup.extras.filter((extra) => extra.selected === true),
                public: extraGroup.public,
                type: extraGroup.type,
            };
        });
        const cartItem: CartItem = {
            name,
            cartId: v4(),
            id,
            amount: amount,
            basePrice,
            extraGroups,
            note,
            options: selectedOptions,
        };
        dispatch.cart.addItem(cartItem);
        handleClose();
    };
    return (
        <div className={styles.root}>
            <ItemQuantityComponent amount={amount} setAmount={setAmount} isAvailable={isAvailable} />
            {isAvailable && (
                <Button
                    variant="contained"
                    color="primary"
                    id="addToCart"
                    className={styles.button}
                    onClick={() => {
                        addToCart(item, selectedExtras, selectedOptions);
                        setExtras(initialSelectedExtras);
                    }}
                >
                    Hinzufügen (
                    {formatPrice(calculatePriceForMenuItem(item, selectedExtras, selectedOptions, isDelivery, amount))})
                </Button>
            )}
            {!isAvailable && (
                <Button variant="contained" color="primary" id="addToCart" className={styles.button} disabled>
                    Aktuell nicht verfügbar
                </Button>
            )}
        </div>
    );
};
