import React, { createRef, useEffect, useMemo, useState } from 'react';

import { Extra, OrderType } from '@bestelleck/utils';
import { Card, CardContent, useMediaQuery, Backdrop } from '@mui/material';
import { useSelector } from 'react-redux';

import { RootState } from '../../store/store';
import { useHorizontalScroll } from '../../util/scroll/horizontalScroll';
import { calculateIsOpen, getUniqueKey, isGroupAvailable, isPreOrderCurrentlyPossible } from '../../util/utils';

import CartComponent from './Cart/Cart';
import styles from './Menu.module.scss';
import MenuCouponComponent from './MenuCoupon/MenuCoupon';
import MenuGroupComponent from './MenuGroup/MenuGroup';

export const MenuComponent: React.FC = () => {
    const orderType = useSelector((state: RootState) => state.orderType.orderType);
    const isDelivery = orderType === OrderType.Delivery;

    const { restaurant } = useSelector((state: RootState) => state.restaurant);

    const scrollRef = useHorizontalScroll();

    const smallScreen = useMediaQuery('(max-width:750px)');
    const isMediumScreen = useMediaQuery('(max-width:1001px)');

    const [backdrop, setBackdrop] = useState(false);

    const sortedGroups = restaurant
        ? restaurant.menu.groups.sort((a, b) => {
              const aIsAvailable = isGroupAvailable(a);
              const bIsAvailable = isGroupAvailable(b);
              return aIsAvailable === bIsAvailable ? 0 : aIsAvailable ? -1 : 1;
          })
        : [];
    const menuGroups = sortedGroups;

    const menu: { name: string; visible: boolean; index: number }[] = sortedGroups.map((group, index) => ({
        name: group.name,
        visible: true,
        index,
    }));

    const [groupIndex, setGroupIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    const [elRefs, setElRefs] = React.useState<{
        menuRefs: any[];
        elementRefs: any[];
    }>({
        menuRefs: [],
        elementRefs: [],
    });
    const menuRef = React.useRef<HTMLDivElement>(null);

    const deliveryAvailable = restaurant?.orderTypes.find((value) => value === OrderType.Delivery.toString())
        ? true
        : false;

    const scrollToGroup = (index: number) => {
        setIsScrolling(true);
        elRefs.elementRefs[index].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        setTimeout(() => setIsScrolling(false), 1000);
    };

    useEffect(() => {
        let previous = 0;
        const menuWidths = elRefs.menuRefs.map((r, index) => {
            if (r.current) {
                const boundingBox = r.current.getBoundingClientRect();
                const current = previous;
                previous = boundingBox.right - boundingBox.left + current + 10;
                if (index === 0) return 0;
                return current;
            }
            return 0;
        });
        const handleScroll = () => {
            for (const i in elRefs.elementRefs) {
                const index = Number(i);
                const ref = elRefs.elementRefs[index];
                const isIn = isInViewport(ref);
                if (isIn && index !== groupIndex) {
                    setGroupIndex(index);
                    if (isMediumScreen && menuRef.current && !isScrolling) {
                        menuRef.current.scrollTo({
                            left: menuWidths[index],
                            behavior: 'smooth',
                        });
                    }
                    break;
                }
            }
        };
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [elRefs, groupIndex, menuRef, isScrolling, isMediumScreen]);

    const isInViewport = (ref: any, offset = 0) => {
        if (!ref.current) return false;
        const top = ref.current.getBoundingClientRect().top;
        return top + offset >= 0 && top + window.innerHeight / 2 <= window.innerHeight;
    };

    useEffect(() => {
        setElRefs((elRefs) => {
            const elementRefs = Array(restaurant?.menu?.groups?.length)
                .fill(null)
                .map((_, i) => elRefs.elementRefs[i] || createRef());
            const menuRefs = Array(restaurant?.menu?.groups?.length)
                .fill(null)
                .map((_, i) => elRefs.menuRefs[i] || createRef());
            return { elementRefs, menuRefs };
        });
    }, [restaurant?.menu?.groups?.length]);

    const isOpen = restaurant ? calculateIsOpen(restaurant, orderType) : false;
    const extras: Extra[] = restaurant?.menu.extras || [];
    const preOrderPossible = isPreOrderCurrentlyPossible(restaurant, isDelivery, isOpen);

    const groups = useMemo(() => {
        const templates = restaurant?.menu.templates ? restaurant.menu.templates : [];
        return menuGroups?.map((group, index) => {
            return (
                <div key={getUniqueKey()} ref={elRefs.elementRefs[index]}>
                    <MenuGroupComponent
                        extras={extras}
                        group={group}
                        templates={templates}
                        searchFilter={''}
                        isOpen={isOpen || preOrderPossible}
                        showItemIdentifier={restaurant?.menu.showMenuItemIdentifiers || false}
                        deliveryAvailable={deliveryAvailable}
                        selectionGroups={restaurant?.menu.selectionGroups || []}
                    ></MenuGroupComponent>
                </div>
            );
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuGroups, extras, elRefs, isOpen]);

    const menuDisplay = menu
        .filter((group) => group.visible)
        .map((group, index) => (
            <div
                ref={elRefs.menuRefs[index]}
                key={getUniqueKey()}
                className={group.index === groupIndex ? styles.menuEntrySelected : styles.menuEntry}
                onClick={() => scrollToGroup(group.index)}
            >
                {group.name}
            </div>
        ));

    const menuCoupon = isDelivery ? restaurant?.menu.coupon?.delivery : restaurant?.menu.coupon?.pickup;

    const minimumOrderValue = isDelivery ? restaurant?.delivery?.places[0]?.minimumOrderValue : 0;

    const groupComponent = () => {
        return (
            <div>
                {menuCoupon && <MenuCouponComponent coupon={menuCoupon}></MenuCouponComponent>}
                {groups}
            </div>
        );
    };

    return (
        <div className={styles.Restaurant}>
            {/* <Helmet>
          <meta charSet="utf-8" />
          <title>
            {restaurant.name} {restaurant.contact.city} | BestellEck
          </title>
          <meta
            name="description"
            content={`Bestelle online von ${restaurant.name} ${restaurant.contact.city} jetzt über BestellEck. Genieße volle Flexibilität und nutze verschiedene Zahlungsmethoden.`}
          ></meta>
        </Helmet>
*/}
            <div className={styles.container}>
                <div className={styles.grid}>
                    <div className={styles.menu}>
                        <div className={styles.menuContainer} ref={menuRef}>
                            {!isMediumScreen && (
                                <Card className={styles.menuCard}>
                                    <CardContent> {menuDisplay}</CardContent>
                                </Card>
                            )}
                            {isMediumScreen && (
                                <div ref={scrollRef} className={styles.menuCard}>
                                    {menuDisplay}
                                </div>
                            )}
                        </div>
                    </div>
                    <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backdrop}></Backdrop>
                    <div className={styles.main}>{groupComponent()}</div>
                    <div className={styles.checkout}>
                        {restaurant && (
                            <CartComponent
                                restaurant={restaurant}
                                showButton={true}
                                collapsible={smallScreen}
                                minimumOrderValue={minimumOrderValue}
                                setBackdrop={setBackdrop}
                            ></CartComponent>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
