import React, { useState } from 'react';

import { DialogTitle } from '@bestelleck/shared';
import { RestaurantDetail } from '@bestelleck/utils';
import { DialogContent, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { AddressComponent } from '../Address/Address';
import { ManualEntryComponent } from '../Address/ManualEntry/ManualEntry';

import styles from './AddressDialog.module.scss';

type SelectOrderDialogProps = {
    open: boolean;
    handleClose: () => void;
    allowManualEntry?: boolean;
    restaurant?: RestaurantDetail;
};

const AddressDialog: React.FC<SelectOrderDialogProps> = ({ open, handleClose, restaurant, allowManualEntry }) => {
    const [tries, setTries] = useState(0);
    const [manualEntry, setManualEntry] = useState(false);
    const smallScreen = useMediaQuery('(max-width:750px)');

    const minTries = 1;

    return (
        <div>
            <Dialog
                onClose={() => {
                    handleClose();
                }}
                aria-labelledby="customized-dialog-title"
                id="selectOrderDialog"
                open={open}
                fullWidth
                fullScreen={smallScreen}
                maxWidth="sm"
                disableEnforceFocus
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        handleClose();
                    }}
                >
                    Adresse eingeben
                </DialogTitle>
                <DialogContent dividers>
                    <div className={styles.section} role="listbox">
                        {!manualEntry && (
                            <AddressComponent
                                restaurant={restaurant}
                                increaseTries={() => setTries(tries + 1)}
                                onSelect={() => {
                                    handleClose();
                                }}
                                showHelp={!allowManualEntry}
                            />
                        )}
                        {tries >= minTries && allowManualEntry && !manualEntry && (
                            <div className={styles.noOption}>
                                Du kannst deine Adresse nicht finden?{' '}
                                <span
                                    className={styles.manual}
                                    onClick={() => {
                                        setManualEntry(true);
                                    }}
                                >
                                    Manuelle Eingabe
                                </span>
                            </div>
                        )}
                        {manualEntry && restaurant && (
                            <ManualEntryComponent
                                handleClose={handleClose}
                                deliveryPlaces={restaurant?.delivery.places}
                            />
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddressDialog;
