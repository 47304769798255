import React from 'react';

import { MenuItem } from '@bestelleck/utils';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { DialogTitle } from '../../../../components/DialogTitle/DialogTitle';
import { ProductInfoDialogComponent } from '../MenuItemDialog/ProductInfo/ProductInfo';

type ProductInfoComponentProps = {
    item: MenuItem;
    open: boolean;
    onClose: () => void;
};

const ProductInfoDialog: React.FC<ProductInfoComponentProps> = ({ onClose, item, open }) => {
    return (
        <div>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="sm">
                <DialogTitle id="customized-dialog-title" onClose={onClose} colorScheme={'secondary'}>
                    Produktinformationen
                </DialogTitle>
                <DialogContent style={{ padding: 0 }} dividers>
                    <ProductInfoDialogComponent hideTitle item={item} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ProductInfoDialog;
