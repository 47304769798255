import React from 'react';

import { BiSad } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import styles from './ErrorScreen.module.scss';

const ErrorScreen: React.FC = () => (
    <div className={styles.root}>
        <h1>Fehler - Das tut uns Leid</h1>
        <BiSad></BiSad>
        <h2>Es ist leider ein Fehler beim Laden der Seite aufgetreten. Bitte versuche die Seite neu zu laden.</h2>
        <h2>Unsere Entwickler wurden benachrichtigt und arbeiten bereits an einer Lösung!</h2>
        <Link to="/">Zurück zur Startseite</Link>
    </div>
);

export default ErrorScreen;
