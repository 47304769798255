import { Models } from '@rematch/core';

import { app } from './app';
import { cart } from './cart';
import { orderType } from './orderType';
import { restaurant } from './restaurant.model';
export interface RootModel extends Models<RootModel> {
    restaurant: typeof restaurant;
    cart: typeof cart;
    app: typeof app;
    orderType: typeof orderType;
}
export const models: RootModel = { restaurant, cart, app, orderType };
