import React from 'react';

import {
    MenuStatus,
    MenuItem as RestaurantMenuItem,
    Extra,
    OrderType,
    MenuItemOption,
    ExtraGroup,
    SelectionGroupType,
} from '@bestelleck/utils';
import { InfoOutlined } from '@mui/icons-material';
import { Card, Typography, CardContent, Snackbar, Alert } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import DescriptionComponent from '../../../components/Description/Description';
import { Dispatch, RootState } from '../../../store/store';
import { CartItem } from '../../../store/types/cart.types';
import { baseUrl } from '../../../util/constants';
import { formatPrice } from '../../../util/utils';

import ImageDialogComponent from './ImageDialog/ImageDialog';
import styles from './MenuItem.module.scss';
import { resolveSelectionGroupsById } from './MenuItem.utils';
import { MenuItemDialog } from './MenuItemDialog/MenuItemDialog';
import ProductInfoDialog from './ProductInfoDialog/ProductInfoDialog';

export type MenuItemProps = {
    index: number;
    item: RestaurantMenuItem;
    extras: Extra[];
    isAvailable: boolean;
    isOpen: boolean;
    deliveryAvailable: boolean;
    showItemIdentifier: boolean;
    selectionGroups: (MenuItemOption | ExtraGroup)[];
};

const MenuItemComponent: React.FC<MenuItemProps> = ({
    item,
    extras,
    isAvailable,
    deliveryAvailable,
    selectionGroups,
    showItemIdentifier,
}) => {
    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);
    const { needToCheck, hasBeenChecked } = useSelector((state: RootState) => state.orderType, shallowEqual);
    const dispatch = useDispatch<Dispatch>();
    const isDelivery = orderType === OrderType.Delivery;

    const route = useSelector((state: RootState) => state.app.route, shallowEqual);

    const [openImageDialog, setOpenImageDialog] = React.useState(false);
    const [openMenuItem, setOpenMenuItem] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [showInfo, setShowInfo] = React.useState(false);

    // check if menu item is available, but only if menu group is available
    isAvailable = isAvailable ? item.status === MenuStatus.Available : false;

    let imageLink, imageLinkSmall;
    if (item.imageLink !== '') {
        imageLink = `${baseUrl}/image/${item.imageLink}`;
        imageLinkSmall = `${imageLink}?height=300`;
    }

    let itemBasePrice = (isDelivery ? item.basePrice.delivery : item.basePrice.pickup) as number;
    if (item.selectionGroupIds.length > 0) {
        const itemSelectionGroups = resolveSelectionGroupsById(item.selectionGroupIds, selectionGroups);
        const selectionGroup = itemSelectionGroups.find(
            (group) => group.type === SelectionGroupType.Option,
        ) as MenuItemOption;
        if (selectionGroup) {
            itemBasePrice += (
                isDelivery
                    ? selectionGroup?.values[0].priceModifier.delivery
                    : selectionGroup?.values[0].priceModifier.pickup
            ) as number;
        }
    }

    const contentClass = imageLink ? styles.content : styles.contentWithoutImage;

    const openOrAddToCart = () => {
        if (!needToCheck || hasBeenChecked) {
            if (item.selectionGroupIds.length === 0) {
                if (isAvailable) {
                    const cartItem: CartItem = {
                        name: item.name,
                        cartId: v4(),
                        id: item.id,
                        amount: 1,
                        basePrice: item.basePrice,
                        extraGroups: [],
                        note: '',
                        options: [],
                    };
                    dispatch.cart.addItem(cartItem);
                } else {
                    setMessage('Das ausgewählte Gericht ist momentan nicht verfügbar');
                    setTimeout(() => setMessage(''), 6000);
                }
            } else {
                setOpenMenuItem(true);
            }
        } else {
            dispatch.orderType.checkOrderType({ previousRoute: route.previous, deliveryAvailable, showDialog: true });
        }
    };

    return (
        <Card className={styles.root} id={item.id}>
            <div className={styles.details} onClick={() => openOrAddToCart()}>
                <CardContent className={[contentClass, isAvailable ? '' : styles.disabled].join(' ')}>
                    <div className={styles.text}>
                        <div className={styles.title}>
                            {showItemIdentifier && item.identifier && (
                                <div className={styles.identifier}>
                                    <span>{item.identifier}</span>
                                </div>
                            )}
                            <Typography component="h5" variant="h5">
                                {item.name}
                            </Typography>
                            <InfoOutlined
                                className={styles.info}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setShowInfo(true);
                                    event.stopPropagation();
                                }}
                            />
                        </div>

                        <DescriptionComponent expanded={false} descriptions={item.descriptions} />
                        <Typography variant="h5" color="textSecondary" className={styles.price}>
                            {formatPrice(itemBasePrice)}
                        </Typography>
                    </div>
                    {imageLink && (
                        <img
                            src={imageLinkSmall}
                            onClick={(event) => {
                                setOpenImageDialog(true);
                                event.stopPropagation();
                                event.preventDefault();
                            }}
                            alt="Bild vom Gericht"
                        />
                    )}
                    {openImageDialog && imageLink && (
                        <ImageDialogComponent
                            image={imageLink}
                            name={item.name}
                            onClose={(event) => {
                                event.stopPropagation();
                                setOpenImageDialog(false);
                            }}
                            open={openImageDialog}
                        ></ImageDialogComponent>
                    )}
                </CardContent>
            </div>

            {openMenuItem && (
                <MenuItemDialog
                    extras={extras}
                    handleClose={() => setOpenMenuItem(false)}
                    item={item}
                    open={openMenuItem}
                    isAvailable={isAvailable}
                    selectionGroups={selectionGroups}
                />
            )}

            {showInfo && <ProductInfoDialog item={item} onClose={() => setShowInfo(false)} open={showInfo} />}
            <Snackbar
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                open={message !== ''}
                autoHideDuration={6000}
                message={message}
            >
                <Alert severity={'error'}>{message}</Alert>
            </Snackbar>
        </Card>
    );
};

export default React.memo(MenuItemComponent);
