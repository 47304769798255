import React from 'react';

import { Coupon, CouponType } from '@bestelleck/utils';

import styles from './MenuCoupon.module.scss';

interface MenuCouponComponentProps {
    coupon: Pick<Coupon, 'id' | 'minimumOrderValue' | 'modifier'>;
}

const MenuCouponComponent: React.FC<MenuCouponComponentProps> = ({ coupon }) => {
    const type = coupon.modifier.type === CouponType.Fixed ? '€' : '%';
    return (
        <div className={styles.menuCoupon}>
            <div className={styles.text}>
                <div>
                    {coupon.modifier.value} {type} Rabatt auf Alles
                </div>
                <span className={styles.subtext}>(wird automatisch im Warenkorb abgezogen)</span>
            </div>
        </div>
    );
};

export default MenuCouponComponent;
