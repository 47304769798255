import { OrderType } from '@bestelleck/utils';
import { createModel } from '@rematch/core';

import { RootModel } from './index';

export type OrderTypeState = {
    orderType: OrderType;
    needToCheck: boolean;
    hasBeenChecked: boolean;
    showDialog: boolean;
};

export const orderType = createModel<RootModel>()({
    state: {
        orderType: OrderType.PickUp,
        needToCheck: false,
        hasBeenChecked: false,
        showDialog: false,
    } as OrderTypeState,
    reducers: {
        setOrderType(state, { orderType }: { orderType: OrderType }) {
            return {
                ...state,
                orderType,
            };
        },
        // TODO can probably be removed
        checkOrderType(
            state,
            {
                previousRoute,
                deliveryAvailable,
                showDialog,
            }: { previousRoute: string; deliveryAvailable: boolean; showDialog: boolean },
        ) {
            const isDelivery = state.orderType === OrderType.Delivery;
            const needToCheckDelivery =
                !(previousRoute.includes('discover') || previousRoute.includes('checkout')) && !isDelivery;

            const needToCheck = needToCheckDelivery && deliveryAvailable && !state.hasBeenChecked;

            return {
                ...state,
                needToCheck,
                showDialog,
                hasBeenChecked: true && showDialog,
            };
        },
        // TODO can probably be removed
        setOrderTypeChecked(state) {
            return {
                ...state,
                hasBeenChecked: true,
            };
        },
    },
});
