import { OrderType, RestaurantDetail } from '@bestelleck/utils';

export type Tag = {
    id: string;
    name: string;
};

export enum RestaurantTags {
    ALL = 'ALL',
}

export const emptyRestaurantDetail: RestaurantDetail = {
    id: '',
    name: '',
    description: '',
    descriptions: [],
    avatarImageLink: '',
    tags: [],
    imageLink: '',
    contact: {
        ownerName: '',
        street: '',
        postalCode: '',
        city: '',
        district: '',
        email: '',
        phoneNumber: '',
        longitude: '',
        latitude: '',
    },
    label: '',
    onlyOnBestellEck: true,
    ratings: { count: 0, average: 0 },
    orderTime: { min: 0, max: 0 },
    orderTypes: [OrderType.PickUp, OrderType.Delivery],
    delivery: {
        days: [],
        places: [],
        exceptions: [],
        label: '',
        supportsPreOrder: false,
    },
    menu: {
        extras: [],
        groups: [],
        templates: [],
        selectionGroups: [],
        showMenuItemIdentifiers: false,
    },
    pickup: { days: [], exceptions: [], label: '', supportsPreOrder: false },
    payment: {
        minimumOnlineOrderValue: 0,
        paymentOptions: [],
    },
    info: [],
    services: [],
};

export type Rating = {
    id: string;
    orderId: string;
    createDate: string;
    values: {
        category: string;
        value: number;
    }[];
    description: string;
    anonym: boolean;
    username: string;
};
