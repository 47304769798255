import React, { useState } from 'react';

import { captureException } from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { updateOrderStatus } from '../../../services/order.service';

import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog';
import { StripeDialog } from './StripeDialog/StripeDialog';

type StripeCheckoutProps = {
    payment: string;
    isOpen: boolean;
    clientSecret: string;
    orderId: string;
    restaurantId: string;
    price: number;
    setIsOpen: (isOpen: boolean) => void;
};

export const StripeCheckout: React.FC<StripeCheckoutProps> = ({
    payment,
    isOpen,
    clientSecret,
    orderId,
    restaurantId,
    setIsOpen,
    price,
}) => {
    const stripeKey = process.env.REACT_APP_STRIPE;

    const [confirmationDialog, setConfirmationDialog] = useState(false);
    if (!stripeKey) {
        return <></>;
    }

    const options = {
        clientSecret,
    };
    const stripePromise = loadStripe(stripeKey);

    if (clientSecret && stripePromise) {
        return (
            <Elements stripe={stripePromise} options={options}>
                <StripeDialog
                    handleClose={() => {
                        setConfirmationDialog(true);
                    }}
                    restaurantId={restaurantId}
                    open={isOpen}
                    paymentMethod={payment}
                    orderId={orderId}
                    price={price}
                />
                <ConfirmationDialog
                    open={confirmationDialog}
                    handleClose={async (aborted) => {
                        setConfirmationDialog(false);
                        if (aborted) {
                            setIsOpen(false);
                            try {
                                await updateOrderStatus(orderId, 'cancelled_before_pay');
                            } catch (error) {
                                captureException(error);
                            }
                        }
                    }}
                />
            </Elements>
        );
    }
    return <></>;
};
