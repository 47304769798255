import React, { useEffect } from 'react';

import { PaymentOption } from '@bestelleck/utils';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FaCoins, FaRegCreditCard, FaGooglePay } from 'react-icons/fa';
import { SiApplepay } from 'react-icons/si';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '../../../store/store';

import styles from './Payment.module.scss';

type PaymentComponentProps = {
    paymentOptions: string[];
};

export const PaymentComponent: React.FC<PaymentComponentProps> = ({ paymentOptions }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<Dispatch>();
    const extraPaymentOptions = useSelector((state: RootState) => state.app.paymentOptions, shallowEqual);
    const selectedPaymentOption = useSelector((state: RootState) => state.app.selectedPaymentOption, shallowEqual);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const baseClass = isSmallScreen ? styles.token : styles.button;

    useEffect(() => {
        if (!paymentOptions.find((option) => option === selectedPaymentOption)) {
            dispatch.appsetSelectedPaymentMethod(paymentOptions[0] as PaymentOption);
        }
    });

    const paymentOptionsElements = paymentOptions.map((payment) => {
        const classes = [baseClass, styles.selected].join(' ');
        const isSelected = payment === selectedPaymentOption;
        let icon: any;
        if (payment === 'cash') {
            icon = <FaCoins className={styles.svg} />;
        } else if (payment === 'paypal') {
            icon = <img className={styles.img} alt="paypal" src={`/assets/paypal.png`} />;
        } else if (payment === 'card') {
            icon = <FaRegCreditCard className={styles.svg} color="black" />;
        } else if (payment === 'giropay' && !isSmallScreen) {
            icon = <img className={styles.giro} alt="giropay" src={`/assets/giro.png`} />;
        } else if (payment === 'giropay' && isSmallScreen) {
            icon = <img className={styles.giroSmall} alt="giropay" src={`/assets/giropay.png`} />;
        } else if (payment === 'applepay' && extraPaymentOptions && extraPaymentOptions.apple) {
            const marginLeft = isSmallScreen ? 6 : 0;
            const marginTop = isSmallScreen ? 0 : -4;
            const marginRight = isSmallScreen ? 6 : 0;
            icon = (
                <SiApplepay
                    style={{ height: 28, width: 28, marginBottom: 0, marginTop, marginLeft, marginRight }}
                    color="black"
                />
            );
        }
        if (payment === 'googlepay' && extraPaymentOptions && extraPaymentOptions.google) {
            icon = <FaGooglePay className={styles.svg} color="black" />;
        }
        if (icon) {
            return (
                <div
                    key={payment}
                    className={isSelected ? classes : baseClass}
                    onClick={() => {
                        dispatch.app.setSelectedPaymentMethod(payment as PaymentOption);
                    }}
                    id={payment}
                >
                    <div>{icon}</div>
                    <div>{t('payment.' + payment)}</div>
                </div>
            );
        }
        return <></>;
    });

    return <div className={isSmallScreen ? styles.paymentOptions : styles.icons}>{paymentOptionsElements}</div>;
};
