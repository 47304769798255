import React, { useEffect } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';

import { Dispatch } from '../../store/store';

export const CheckPaymentComponent: React.FC = () => {
    const stripe = useStripe();
    const dispatch = useDispatch<Dispatch>();

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'Demo total',
                    amount: 1099,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
            pr.canMakePayment().then((result) => {
                if (result) {
                    dispatch.app.setPaymentMethods({
                        apple: result.applePay,
                        google: result.googlePay,
                        link: result.link,
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    return <></>;
};
