import React from 'react';

import { ExtraMapped } from '@bestelleck/utils';
import { Checkbox, FormControlLabel } from '@mui/material';

import { formatPrice, getUniqueKey } from '../../../../util/utils';
import { MappedExtraGroup } from '../MenuItem.utils';

import styles from './ExtraItem.module.scss';

export type ExtraItemProps = {
    extraItem: ExtraMapped;
    isDelivery: boolean;
    index: number;
    groupIndex: number;
    selectedExtras: MappedExtraGroup[];
    handleExtraChange: (group: number, itemId: string) => void;
};

const ExtraItemComponent: React.FC<ExtraItemProps> = ({
    extraItem,
    index,
    isDelivery,
    selectedExtras,
    groupIndex,
    handleExtraChange,
}) => {
    const label = `${extraItem.name}`;
    const basePrice = (isDelivery ? extraItem.basePrice.delivery : extraItem.basePrice.pickup) as number;
    return (
        <div className={styles.checkbox} key={getUniqueKey()}>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={selectedExtras[groupIndex].extras[index].selected}
                        onChange={() => {
                            handleExtraChange(groupIndex, extraItem.id);
                        }}
                        name={extraItem.name}
                    />
                }
                label={label}
            />
            <span className={styles.priceLabel}>{`+ ${formatPrice(basePrice)}`}</span>
        </div>
    );
};

export default ExtraItemComponent;
