import React from 'react';

import { DeliveryPlace } from '@bestelleck/utils';
import { Button, FormControl, InputLabel, Select, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '../../../store/store';

import styles from './ManualEntry.module.scss';

type ManualEntryComponentProps = {
    deliveryPlaces: DeliveryPlace[];
    handleClose: () => void;
};

type FormData = {
    address: string;
    houseNumber: string;
    city: string;
};

export const ManualEntryComponent: React.FC<ManualEntryComponentProps> = ({ deliveryPlaces, handleClose }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const dispatch = useDispatch<Dispatch>();
    const locationInfo = useSelector((state: RootState) => state.app.location, shallowEqual);

    const onSubmit = (data: FormData) => {
        const deliveryPlace = deliveryPlaces.find((place) => place.locationId === data.city);
        if (deliveryPlace) {
            dispatch.app.setPostalCode({
                ...locationInfo,
                manual: {
                    address: data.address,
                    houseNumber: data.houseNumber,
                    deliveryPlace,
                },
            });
            handleClose();
        }
    };

    const places = deliveryPlaces.map((place) => (
        <option key={`${place.locationId}-${place.city}`} value={place.locationId}>
            {place.postalCode} {place.city}
        </option>
    ));

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.row}>
                <TextField
                    {...register('address', {
                        minLength: {
                            value: 5,
                            message: 'Die angegebene Adresse ist zu kurz',
                        },
                        maxLength: {
                            value: 100,
                            message: 'Die angegebene Adresse ist zu lang',
                        },
                        required: 'Bitte gebe deine Adresse ein',
                    })}
                    error={errors.address ? true : false}
                    helperText={errors?.address ? errors.address.message : null}
                    required
                    defaultValue={locationInfo.manual?.address || ''}
                    id="address"
                    name="address"
                    label="Straße"
                    variant="outlined"
                    className={styles.half}
                    color="secondary"
                />
                <TextField
                    {...register('houseNumber', {
                        minLength: 1,
                        maxLength: 20,
                        required: 'Bitte gebe deine Hausnummer ein',
                    })}
                    error={errors.houseNumber ? true : false}
                    helperText={errors?.houseNumber ? errors.houseNumber.message : null}
                    required
                    defaultValue={locationInfo.manual?.houseNumber || ''}
                    id="houseNumber"
                    name="houseNumber"
                    label="Hausnummer"
                    variant="outlined"
                    className={styles.quarter}
                    color="secondary"
                />
            </div>
            <FormControl variant="outlined" className={[styles.half, styles.select].join(' ')}>
                <InputLabel color="secondary" htmlFor="city-select" id="city-select-label">
                    Stadt
                </InputLabel>
                <Select
                    native
                    id="city-select"
                    {...register('city')}
                    required
                    label="Stadt"
                    variant="outlined"
                    className={styles.half}
                    color="secondary"
                    labelId="city-select-label"
                >
                    {places}
                </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary" className={styles.button}>
                Übernehmen
            </Button>
        </form>
    );
};
