import moment from 'moment';

export const getDisplayDate = (date: Date): string => {
    const day = date.toLocaleString('de', { weekday: 'long' });
    return `${day} ${date.toLocaleDateString('de')} - ${padNumber(date.getHours())}:${padNumber(
        date.getMinutes(),
    )} Uhr`;
};

export const padNumber = (number: number): string => {
    return String(number).padStart(2, '0');
};

export const getTime = (time: number): string => {
    const now = new Date();
    const hours = Math.trunc(time / 60);
    const minutes: number = time % 60;
    return moment({
        years: now.getFullYear(),
        months: now.getMonth(),
        date: now.getDate(),
        hours: hours,
        minutes: minutes,
        seconds: 0,
        milliseconds: 0,
    }).toISOString();
};
