import React from 'react';

import { LocationOn, Phone } from '@mui/icons-material';
import { Button, Card, CardContent, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { RootState } from '../../store/store';

import styles from './Contact.module.scss';

export const Contact: React.FC = () => {
    const theme = useTheme();
    const storeRestaurant = useSelector((state: RootState) => state.restaurant.restaurant);

    return (
        <div className={styles.root}>
            <div style={{ fontSize: 36 }}>Kontakt</div>

            <div
                style={{
                    paddingTop: 50,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                    gridTemplateRows: '1fr',
                    paddingBottom: 30,
                    gap: '0px 0px',
                }}
            >
                <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div>
                            <LocationOn
                                style={{ fontSize: 40, color: theme.palette.primary.main }}
                                className={styles.icon}
                            />
                        </div>

                        <div>
                            <div>{storeRestaurant?.name}</div>
                            <div>{storeRestaurant?.contact.street}</div>
                            <div>
                                {storeRestaurant?.contact.postalCode} {storeRestaurant?.contact.city}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div>
                            <Phone
                                style={{ fontSize: 40, color: theme.palette.primary.main }}
                                className={styles.icon}
                            />
                        </div>

                        <div>
                            <div>Telefon: {storeRestaurant?.contact.phoneNumber}</div>
                        </div>
                    </div>
                </div>

                <div>
                    <Card style={{ padding: 30, margin: 20 }}>
                        <CardContent style={{ textAlign: 'start' }}>
                            <div style={{ fontWeight: 'bold' }}>Name</div>
                            <TextField
                                style={{ marginTop: 5, marginBottom: 20, background: 'white', display: 'flex' }}
                            />
                            <div style={{ fontWeight: 'bold' }}>E-Mail Adresse</div>
                            <TextField
                                style={{ marginTop: 5, marginBottom: 20, background: 'white', display: 'flex' }}
                            />
                            <div style={{ fontWeight: 'bold' }}>Ihre Nachricht</div>
                            <TextField
                                style={{
                                    marginTop: 5,
                                    marginBottom: 20,
                                    background: 'white',
                                    display: 'flex',
                                }}
                                multiline
                                rows={6}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: 50,
                                    fontWeight: 'bold',
                                    fontSize: 20,
                                    textTransform: 'none',
                                }}
                            >
                                Absenden
                            </Button>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};
