import React from 'react';

import { Skeleton } from '@mui/material';

export const CheckoutSkeletonComponent: React.FC = () => {
    return (
        <div>
            <h1 style={{ marginTop: 0 }}>Vielen Dank für deine Bestellung!</h1>
            <Skeleton height={50} />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton style={{ marginTop: 20 }} height={50} />
            <Skeleton />
            <Skeleton />
        </div>
    );
};
