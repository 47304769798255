import { roundPrice } from "../utils/price.util";
import { CouponType } from "./coupon-type.enum";
import { Coupon } from "./coupon.interface";

export const calculateCouponDiscount = (currentPrice: number, coupon: Coupon | undefined): number => {
    if (coupon && currentPrice >= coupon.minimumOrderValue) {
        if (coupon.modifier.type === CouponType.Fixed) {
            return coupon.modifier.value;
        } else if (coupon.modifier.type === CouponType.Percentage) {
            return roundPrice((currentPrice / 100) * coupon.modifier.value);
        }
    }

    return 0;
};
