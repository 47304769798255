import React, { useState } from 'react';

import { Extra, ExtraGroup, MenuItem } from '@bestelleck/utils';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { Collapse } from '@mui/material';

import { getUniqueKey } from '../../../../util/utils';
import ExtraItemComponent from '../ExtraItem/ExtraItem';
import { getMappedExtra, MappedExtraGroup } from '../MenuItem.utils';

import styles from './ExtraGroup.module.scss';

export type ExtraGroupProps = {
    extraGroup: ExtraGroup;
    item: MenuItem;
    extras: Extra[];
    isDelivery: boolean;
    groupIndex: number;
    selectedExtras: MappedExtraGroup[];
    handleExtraChange: (group: number, itemId: string) => void;
};

const ExtraGroupComponent: React.FC<ExtraGroupProps> = ({
    extraGroup,
    item,
    extras,
    isDelivery,
    selectedExtras,
    groupIndex,
    handleExtraChange,
}) => {
    const [expanded, setExpanded] = useState(false);

    const mappedExtraItem = getMappedExtra(extraGroup, item, extras);
    const displayedExtras = mappedExtraItem.length > 10 ? 5 : 10;

    const firstExtras = mappedExtraItem.splice(0, displayedExtras).map((extraItem, index) => {
        return (
            <ExtraItemComponent
                key={`${extraItem.id}-${item.id}`}
                extraItem={extraItem}
                groupIndex={groupIndex}
                handleExtraChange={handleExtraChange}
                index={index}
                isDelivery={isDelivery}
                selectedExtras={selectedExtras}
            ></ExtraItemComponent>
        );
    });

    const extraEntry = mappedExtraItem.map((extraItem, index) => {
        return (
            <ExtraItemComponent
                key={`${extraItem.id}-${item.id}`}
                extraItem={extraItem}
                groupIndex={groupIndex}
                handleExtraChange={handleExtraChange}
                index={index + displayedExtras}
                isDelivery={isDelivery}
                selectedExtras={selectedExtras}
            ></ExtraItemComponent>
        );
    });
    return (
        <div className={styles.extraContainer} key={getUniqueKey()}>
            <div className={styles.sectionHeader}>{extraGroup.name}</div>
            <div className={styles.extras}>
                {firstExtras}
                <Collapse in={expanded} timeout="auto" unmountOnExit className={styles.extrasContainer}>
                    <div className={styles.extraEntries}>{extraEntry}</div>
                </Collapse>
            </div>

            {!expanded && extraEntry.length > 0 && (
                <div
                    id="expandExtras"
                    onClick={() => {
                        setExpanded(true);
                    }}
                    className={styles.more}
                >
                    <KeyboardArrowDown />
                    <div>Weitere {extraEntry.length} anzeigen</div>
                </div>
            )}
            {expanded && (
                <div
                    onClick={() => {
                        setExpanded(false);
                    }}
                    className={styles.more}
                >
                    <KeyboardArrowUp /> <div> Weniger anzeigen</div>
                </div>
            )}
        </div>
    );
};

export default ExtraGroupComponent;
