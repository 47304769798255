import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import createPersistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';

import { models, RootModel } from './models';

export const store = init<RootModel>({
    models,
    plugins: [
        createPersistPlugin({
            key: 'root',
            storage,
            version: 1,
        }),
    ],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
