export enum OrderStatus {
    Unpayed = "unpayed",
    Open = "open",
    AutoAccepted = "auto_accepted",
    InProgress = "in_progress",
    ReadyForPickup = "ready_for_pickup",
    InDelivery = "in_delivery",
    SuccessfulOrder = "order_successfully",
    FailedOrder = "order_failed",
    Declined = "declined",
    CancelledBeforePay = "cancelled_before_pay",
    NeverAccepted = "never_accepted",
}
