import React, { useState } from 'react';

import { Description } from '@bestelleck/utils';
import { Collapse } from '@mui/material';

import { getDescriptionText, isDescriptionsExpandable, seperateDescriptions } from '../../util/descriptions';

import styles from './GroupDescription.module.scss';

interface GroupDescriptionComponentProps {
    descriptions: Description[];
}

const GroupDescriptionComponent: React.FC<GroupDescriptionComponentProps> = ({ descriptions }) => {
    const [expanded, setExpanded] = useState(false);
    const canExpand = isDescriptionsExpandable(descriptions);
    if (!canExpand) {
        return (
            <div className={styles.root}>
                {descriptions.map((desc, index) => {
                    return (
                        <div key={index} className={styles.line}>
                            {getDescriptionText(desc)}
                        </div>
                    );
                })}
            </div>
        );
    } else if (canExpand) {
        const [defaultDescriptions, expandedDescriptions] = seperateDescriptions(descriptions);
        return (
            <div className={styles.root}>
                {defaultDescriptions.map((desc, index) => {
                    return (
                        <div key={index} className={styles.descriptionEntry}>
                            {getDescriptionText(desc)}
                        </div>
                    );
                })}
                {!expanded && (
                    <div onClick={() => setExpanded(true)} className={styles.more}>
                        Weiterlesen
                    </div>
                )}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {expandedDescriptions.map((desc, index) => {
                        return (
                            <div className={styles.descriptionEntry} key={index}>
                                {getDescriptionText(desc)}
                            </div>
                        );
                    })}
                </Collapse>
                {expanded && (
                    <div onClick={() => setExpanded(false)} className={styles.more}>
                        Weniger anzeigen
                    </div>
                )}
            </div>
        );
    }
    return <></>;
};

export default GroupDescriptionComponent;
