import React from 'react';

import { useMediaQuery } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { FormResult } from '../../Checkout';

import { ApplePayButton } from './ApplePayButton';

export interface ApplePayProps {
    price: number;
    restaurantId: string;
    formIsValid: boolean;
    setIsLoading: (isLoading: boolean) => void;
    triggerValidation: () => void;
    getClientSecret: (
        data:
            | FormResult
            | {
                  [x: string]: any;
              },
    ) => Promise<{ orderId: string; clientSecret: string } | undefined>;
    data:
        | FormResult
        | {
              [x: string]: any;
          };
}

export const ApplePay: React.FC<ApplePayProps> = ({
    price,
    restaurantId,
    setIsLoading,
    getClientSecret,
    data,
    formIsValid,
    triggerValidation,
}) => {
    const stripeKey = process.env.REACT_APP_STRIPE;
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    if (!stripeKey) {
        return <></>;
    }

    const stripePromise = loadStripe(stripeKey);

    if (stripePromise) {
        return (
            <div style={{ width: isSmallScreen ? '100%' : '250px', marginTop: '20px' }}>
                <Elements stripe={stripePromise} key={price}>
                    <ApplePayButton
                        triggerValidation={triggerValidation}
                        setIsLoading={setIsLoading}
                        getClientSecret={getClientSecret}
                        price={price}
                        restaurantId={restaurantId}
                        data={data}
                        formIsValid={formIsValid}
                    />
                </Elements>
            </div>
        );
    }
    return <></>;
};
