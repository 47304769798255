import { RestaurantDetail } from '@bestelleck/utils';
import { createModel } from '@rematch/core';

import { baseUrl } from '../../util/constants';

import { RootModel } from './index';

export type RestaurantState = {
    restaurant: RestaurantDetail | null;
};

export const restaurant = createModel<RootModel>()({
    state: {
        restaurant: null,
    } as RestaurantState,
    reducers: {
        getRestaurantSuccess(state, restaurant: RestaurantDetail) {
            return { ...state, restaurant };
        },
    },
    effects: (dispatch) => ({
        async getRestaurant(restaurantId: string) {
            try {
                const url = `${baseUrl}/restaurants/${restaurantId}?useNewScheduleDays=true&useSelectionGroups=true`;
                const restaurantResponse = await fetch(url);
                const restaurant = await restaurantResponse.json();
                dispatch.restaurant.getRestaurantSuccess(restaurant);
            } catch (error) {
                // dispatch.app.showMessage({
                //     message: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
                //     severity: 'error',
                // });
            }
        },
    }),
});
