import React from 'react';

import { formatPrice } from '@bestelleck/shared';
import { Restaurant } from '@bestelleck/utils';
import { useMediaQuery } from '@mui/material';

import styles from './DeliveryPlaces.module.scss';

interface DeliveryPlacesProps {
    restaurant: Restaurant | undefined | null;
}

export const DeliveryPlaces: React.FC<DeliveryPlacesProps> = ({ restaurant }) => {
    const isMobile = useMediaQuery('(max-width:560px)');
    if (!restaurant) {
        return null;
    }

    return (
        <div className={styles.root}>
            <h2 style={{ color: 'white' }}>Liefergebiete</h2>
            {!isMobile && (
                <div className={styles.grid}>
                    <div className={styles.header}>Liefergebiet</div>
                    <div className={styles.header}>Mindestbestellwert</div>
                    <div className={styles.header}>Liefergebühr</div>
                    {restaurant.delivery.places.map((place) => {
                        return (
                            <>
                                <div>
                                    {place.postalCode} {place.city}
                                </div>
                                <div>{formatPrice(place.minimumOrderValue)}</div>
                                <div> {formatPrice(place.fee)}</div>
                            </>
                        );
                    })}
                </div>
            )}
            {isMobile && (
                <div>
                    {restaurant.delivery.places.map((place) => {
                        return (
                            <div key={place.locationId} style={{ margin: 20 }}>
                                <div style={{ fontWeight: 'bold' }}>
                                    {place.postalCode} {place.city}
                                </div>
                                <div>Mind. - {formatPrice(place.minimumOrderValue)}</div>
                                <div>Gebühr - {formatPrice(place.fee)}</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
