import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Dispatch } from '../../store/store';
import { restaurantId } from '../../util/constants';

export const LoadRestaurantComponent: React.FC = () => {
    const dispatch = useDispatch<Dispatch>();
    useEffect(() => {
        dispatch.restaurant.getRestaurant(restaurantId);
    }, [dispatch]);
    return <></>;
};
