import { Place } from "./place.interface";

export const getPlaceDisplay = (place: Place): string => {
    let roadDisplay;
    const cityDisplay = getCityDisplay(place);

    if (place.address?.road && place.address?.house_number) {
        roadDisplay = `${place.address.road} ${place.address.house_number},`;
    } else if (place.address?.road) {
        roadDisplay = `${place.address.road}, `;
    } else if (place.address?.city_block && place.address.house_number) {
        roadDisplay = `${place.address.city_block} ${place.address.house_number},`;
    } else if (place.address?.city_block) {
        roadDisplay = `${place.address.city_block},`;
    } else if (place.address?.neighbourhood) {
        roadDisplay = `${place.address.neighbourhood}, `;
    }

    if (roadDisplay) {
        return `${roadDisplay} ${place.address?.postcode} ${cityDisplay}`;
    } else {
        return `${place.address?.postcode} ${cityDisplay}`;
    }
};

export const getCityDisplay = (place: Place): string => {
    let cityDisplay = "";

    if (place.address?.town) {
        cityDisplay = place.address?.town;
    } else if (place.address?.city) {
        cityDisplay = place.address?.city;
    } else if (place.address?.village) {
        cityDisplay = place.address?.village;
    } else if (place.address?.municipality) {
        cityDisplay = place.address.municipality;
    }
    return cityDisplay;
};

export const removeDuplicatesByName = (places: Place[]): Place[] => {
    return places.filter((v, i, a) => a.findIndex((v2) => v2.customDisplayName === v.customDisplayName) === i);
};

export const filterGeoResults = (places: Place[]): Place[] => {
    const excludeClasses = ["landuse", "waterway", "natural"];
    const excludedTypes = [
        "locality",
        "village",
        "motorway_junction",
        "bus_stop",
        "stop",
        "platform",
        "halt",
        "service_station",
    ];
    const filtered = places.filter(
        (place) =>
            !excludeClasses.find((excludeClass) => place.class === excludeClass) &&
            !excludedTypes.find((excludedType) => place.type === excludedType) &&
            place.address?.postcode !== undefined
    );
    return filtered.map((place) => {
        return {
            ...place,
            customDisplayName: getPlaceDisplay(place),
        };
    });
};
