import React, { useState } from 'react';

import { formatPrice } from '@bestelleck/shared';
import { Order, OrderStatus, OrderType, SelectionGroupType } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { SentimentDissatisfied, SentimentNeutral, SentimentSatisfiedAlt } from '@mui/icons-material';
import { Button, Divider, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Alert } from '../../../components/Alert/Alert';
import { getCouponTypeString } from '../../../util/utils';
import RateDialogComponent from '../RateDialog/RateDialog';

import styles from './OrderDetails.module.scss';

interface OrderDetailsProps {
    order: Order;
    setLoading: (isLoading: boolean) => void;
}

export const OrderDetailsComponent: React.FC<OrderDetailsProps> = ({ order, setLoading }) => {
    const { t } = useTranslation();
    const [showRatingDialog, setShowRatingDialog] = useState({ open: false, rating: 0 });
    const [rated, setRated] = useState(false);
    const [showRating, setShowRating] = useState(true);

    const { trackEvent } = useMatomo();

    const isDelivery = order.type === OrderType.Delivery;
    const items = order.items.map((item) => {
        const extras = [];
        const options: string[] = [];
        if (item.selectionGroups) {
            for (const selectionGroup of item.selectionGroups) {
                if (selectionGroup.type === SelectionGroupType.Option) {
                    options.push(selectionGroup.selectedValue.name);
                } else if (selectionGroup.type === SelectionGroupType.ExtraGroup) {
                    const extrasName = selectionGroup.extras.map((extra) => extra.name).join(', ');
                    if (extrasName !== '') {
                        extras.push(
                            <div className={styles.extras} key={selectionGroup.name}>
                                <span className={styles.label}>{selectionGroup.name}: </span>
                                {extrasName}
                            </div>,
                        );
                    }
                }
            }
        }

        const optionDisplay = options.join(', ');
        return (
            <div className={styles.orderItem} key={item.id}>
                <div>
                    <div className={styles.item}>
                        <span className={styles.amount}>{item.amount}x</span>
                        <span className={styles.itemName}>{item.name}</span>
                    </div>
                    {options.length > 0 && (
                        <div className={styles.extras}>
                            <span className={styles.label}>Auswahl:</span> {optionDisplay}
                        </div>
                    )}
                    {extras}
                    {item.note && (
                        <div className={styles.extras}>
                            <span className={styles.label}>Anmerkung:</span> {item.note}
                        </div>
                    )}
                </div>
                <div>{formatPrice(item.totalPrice)}</div>
            </div>
        );
    });

    const preferredTime = order.preferredTime
        ? new Date(order.preferredTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        : 'Schnellstmöglich';

    let subtotal = 0;
    order.items.map((item) => {
        subtotal += item.amount * item.totalPrice;
        return item;
    });

    const menuCouponType = getCouponTypeString(order.menuCoupon?.type || '');

    const { status: lastStatus, reason } = order.status.transitions[order.status.transitions.length - 1];
    let status: string | JSX.Element | undefined;
    if (lastStatus === OrderStatus.Open || lastStatus === OrderStatus.AutoAccepted) {
        status = (
            <div>
                Deine Bestellung wurde an das Restaurant übermittelt.
                <div className={styles.extraText}>
                    Du kannst diese Seite aktualisieren, um den aktuellen Status zu überprüfen.
                </div>
            </div>
        );
    } else if (order.status.estimatedFinishTime) {
        const date = new Date(order.status.estimatedFinishTime);
        status = `Das Restaurant hat deine Bestellung angenommen und diese wird voraussichtlich am ${date.toLocaleDateString(
            'de-DE',
            { day: '2-digit', month: '2-digit' },
        )} um ${date.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' })} Uhr ${
            order.type === OrderType.PickUp ? 'zur Abholung bereitstehen.' : 'zu dir geliefert werden.'
        }`;
    } else if (lastStatus === OrderStatus.Declined) {
        status = `Das Restaurant hat deine Bestellung mit folgender Begründung abgelehnt: ${reason}`;
    } else if (lastStatus === OrderStatus.NeverAccepted) {
        status =
            'Das Restaurant hat deine Bestellung nicht innerhalb von 15 Minuten bearbeitet, daher wurde die Bestellung automatisch storniert.';
    } else if (lastStatus === OrderStatus.SuccessfulOrder) {
        status = 'Die Bestellung wurde abgeschlossen';
    }

    const isDeclined = lastStatus === OrderStatus.Declined || lastStatus === OrderStatus.NeverAccepted;
    const isSent = lastStatus === OrderStatus.Open || lastStatus === OrderStatus.AutoAccepted;
    const inProgress = order.status.estimatedFinishTime;

    return (
        <div className={styles.root}>
            {showRating && (
                <>
                    <h3>Wie zufrieden warst du mit dem Bestellprozess?</h3>
                    <div className={styles.rating}>
                        <SentimentDissatisfied
                            onClick={() => setShowRatingDialog({ open: true, rating: 1 })}
                            className={styles.bad}
                        />
                        <SentimentNeutral
                            onClick={() => setShowRatingDialog({ open: true, rating: 2 })}
                            className={styles.neutral}
                        />
                        <SentimentSatisfiedAlt
                            onClick={() => setShowRatingDialog({ open: true, rating: 3 })}
                            className={styles.good}
                        />
                    </div>
                </>
            )}
            <h1 id="finished">Vielen Dank für deine Bestellung!</h1>
            {status && (
                <div className={styles.section}>
                    <h2 className={styles.header}>
                        <div>Bestellstatus</div>
                        <div className={styles.indicator}>
                            {inProgress && <div className={styles.good}></div>}
                            {isSent && <div className={styles.yellow}></div>}
                            {isDeclined && <div className={styles.red}></div>}
                        </div>
                    </h2>
                    <div className={styles.content}>
                        <div>{status}</div>
                    </div>
                </div>
            )}

            <div className={styles.section}>
                <h2 className={styles.header}> Bestellübersicht </h2>
                <div className={styles.content}>
                    <div className={styles.restaurantName}>{order.restaurant?.name}</div>
                    <div>{items}</div>
                    <Divider />
                    <div className={styles.line}>
                        <div>Bestellsumme: </div>
                        <div>{formatPrice(subtotal)}</div>
                    </div>
                    {order.menuCoupon && (
                        <div className={styles.line}>
                            <div>
                                Rabatt ({order.menuCoupon.value}
                                {menuCouponType}):
                            </div>
                            <div>- {formatPrice(order.menuCoupon.discount)}</div>
                        </div>
                    )}
                    {order.coupon && (
                        <div className={styles.line}>
                            <div>Gutschein ({order.coupon.key}):</div>
                            <div>- {formatPrice(order.coupon.discount)}</div>
                        </div>
                    )}
                    <Divider />
                    {isDelivery && order.delivery && (
                        <div className={styles.line}>
                            <div>Lieferkosten:</div>
                            <div>{formatPrice(order.delivery.fee)}</div>
                        </div>
                    )}
                    <div style={{ fontWeight: 600 }} className={styles.line}>
                        <div>Gesamtpreis:</div>
                        <div>{formatPrice(order.totalPrice)}</div>
                    </div>
                    <Divider />
                    <div className={styles.line}>
                        <div>Zahlungsmöglichkeit:</div>
                        <div>{t('payment.' + order.payment.option)}</div>
                    </div>
                    <Divider />
                    <div className={styles.line}>
                        <div>Deine Bestellnummer:</div>
                        <div>{order.key}</div>
                    </div>
                </div>
            </div>
            {isDelivery && order.delivery && (
                <div className={styles.section}>
                    <h2 className={styles.header}> Bestellinformationen </h2>
                    <div className={styles.content}>
                        <div className={styles.detailsHeader}>Persönliche Daten</div>
                        <div className={styles.infoLine}>{order.contact.fullName}</div>
                        <div className={styles.infoLine}>{order.contact.email}</div>
                        <div className={styles.infoLine}>{order.delivery.street}</div>
                        <div className={styles.infoLine}>
                            {order.delivery.postalCode} {order.delivery.city}
                        </div>
                        <div className={styles.infoLine}>{order.contact.phoneNumber}</div>
                        <Divider />
                        <div className={styles.detailsHeader}>Lieferzeit</div>
                        <div className={styles.infoLine}>{preferredTime}</div>
                    </div>
                </div>
            )}

            {order.type === OrderType.PickUp && (
                <div className={styles.section}>
                    <h2 className={styles.header}> Abholinformationen </h2>
                    <div className={styles.content}>
                        <div className={styles.detailsHeader}>Persönliche Daten</div>
                        <div className={styles.infoLine}>{order.contact.fullName}</div>
                        <div className={styles.infoLine}>{order.contact.email}</div>
                        <div className={styles.infoLine}>{order.contact.phoneNumber}</div>
                        <Divider />
                        <div className={styles.detailsHeader}>Details</div>
                        <div className={styles.infoLine}>{order.restaurant?.name}</div>
                        <div className={styles.infoLine}>{order.restaurant?.contact.street}</div>
                        <div className={styles.infoLine}>
                            {order.restaurant?.contact.postalCode} {order.restaurant?.contact.city}
                        </div>
                        <div className={styles.infoLine}>{order.restaurant?.contact.phoneNumber}</div>
                        <Divider />
                        <div className={styles.detailsHeader}>Abholzeit</div>
                        <div className={styles.infoLine}>{preferredTime}</div>
                    </div>
                </div>
            )}
            <Link to={`/discover`}>
                <Button type="submit" variant="contained" color="primary" className={styles.button}>
                    Zurück zur Übersicht
                </Button>
            </Link>
            {showRatingDialog.open && (
                <RateDialogComponent
                    setLoading={setLoading}
                    rating={showRatingDialog.rating}
                    orderId={order.id}
                    open={showRatingDialog.open}
                    handleClose={(rating: number, successful: boolean) => {
                        setShowRatingDialog({ open: false, rating });
                        trackEvent({ action: 'Dismiss feedback', category: 'Completed' });
                        if (successful) {
                            setRated(true);
                            setShowRating(false);
                            setTimeout(() => setRated(false), 2000);
                        }
                    }}
                />
            )}
            <Snackbar open={rated} autoHideDuration={2000} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                <Alert severity="success">Danke für dein Feedback</Alert>
            </Snackbar>
        </div>
    );
};
