const MenuGroupImages = new Map<string, string>();
MenuGroupImages.set("Alkoholfreie Getränke", "505c8094-324d-4180-ade0-56a69e13655a.jpg");
MenuGroupImages.set("Alkoholische Getränke", "c0786722-9fc0-49e4-aa14-7968ad1d82c1.jpg");
MenuGroupImages.set("Backofenkartoffel", "3cfe85b9-8aa7-4a80-b6a0-149732721d92.jpg");
MenuGroupImages.set("Baklava", "38dc0aba-d26e-4eed-a795-b277eb0f4ed6.jpg");
MenuGroupImages.set("Ben and Jerry's", "bb0aa3d1-ac89-4f80-83cf-11547c890d92.jpg");
MenuGroupImages.set("Burger", "5ee851f9-25aa-448c-bbdc-90337542ac41.jpg");
MenuGroupImages.set("Burger (Do-it-yourself)", "d929250b-23a2-4630-b2b1-1d77c3c55640.jpg");
MenuGroupImages.set("Burger (Veggie)", "db2b3fba-e3a7-4092-96c1-12cfa7ff21dd.jpg");
MenuGroupImages.set("Calamaris", "0ed2dae4-777c-4312-b8ea-e46931c8a825.jpg");
MenuGroupImages.set("Calzone", "0791c4a4-cba0-4d7a-a446-843ea22e2c1a.jpg");
MenuGroupImages.set("Chicken Burger", "1a3f1fb2-c23f-4220-a77c-30cfdd7e00e6.jpg");
MenuGroupImages.set("Chicken Nuggets", "7041af8a-daf8-432d-b34f-e231667b6955.jpg");
MenuGroupImages.set("Churros", "cf360867-5e43-4330-b605-ffe10b0d1856.jpg");
MenuGroupImages.set("Cordon Bleu", "b035d072-277b-4cf4-a074-e2afef94f493.jpg");
MenuGroupImages.set("Cupcakes", "3448604c-d802-464c-9999-3ef0f9014f30.jpg");
MenuGroupImages.set("Döner", "4fde85ef-8fa7-424a-9dd8-e299ffee1129.jpg");
MenuGroupImages.set("Dönerteller", "2d7ea065-cab9-4f40-a9c1-f54836b93208.jpg");
MenuGroupImages.set("Eis", "708e6e73-9a22-48bd-9a65-5c70062f6dd1.jpg");
MenuGroupImages.set("Falafel", "4a86c92a-67c1-40cf-bae8-4a7c7c554ae9.jpg");
MenuGroupImages.set("Fisch", "152f4b1f-03fc-49ef-9e24-28688591f35b.jpg");
MenuGroupImages.set("Flammkuchen", "f6e96516-77f5-43a1-823b-ba4c1b9aaa25.jpg");
MenuGroupImages.set("Frühlingsrollen", "25f2867c-51d1-4e1b-a2a2-91afeee68d62.jpg");
MenuGroupImages.set("Gebratener Reis ", "153561d7-662d-4237-bba0-cdd9898951d1.jpg");
MenuGroupImages.set("Gebratene Nudeln", "734df153-a54b-4d25-9e03-6c1751e26caa.jpg");
MenuGroupImages.set("Gebratene Nudeln 2", "f9c8740d-46e6-4772-be19-df113436edf5.jpg");
MenuGroupImages.set("Gnocci", "eb21323f-274f-4270-b859-5ce3489ca9cc.jpg");
MenuGroupImages.set("Gulap", "cde796d0-ac7f-4a78-be79-93afe6c264d9.jpg");
MenuGroupImages.set("Gyros", "a0505bda-ea5a-4717-8f40-32e475ebe215.jpg");
MenuGroupImages.set("Hähnchen-Schnitzel", "09802f72-3feb-428c-b720-1f3c9384acd5.jpg");
MenuGroupImages.set("Indisch Biryani", "1837d82d-9521-4887-b8dd-21ace8ac3339.jpg");
MenuGroupImages.set("Indisches Chicken Curry", "c2f20a8f-cb6b-4287-b0c8-9a95e85e7f8e.jpg");
MenuGroupImages.set("Indisches Essen", "26e1d4b7-d914-45d8-9ea3-2e4c53d095dc.jpg");
MenuGroupImages.set("Indisches Naan Brot", "3021eaae-08d6-49d8-b2ab-63eb1cf7c4ad.jpg");
MenuGroupImages.set("Indisch Samosa", "011f5be7-0982-4e7c-8ce5-248effc13678.jpg");
MenuGroupImages.set("Indisch Tandoori", "32ff1488-da78-49c2-8e8e-fd225cda0885.jpg");
MenuGroupImages.set("Indisch Vegetarisch", "d633f7cb-1daf-4843-b611-7662585ae7fa.jpg");
MenuGroupImages.set("Indische Vorspeisen", "055ef8b6-cf93-4c94-90a2-d71995ec7201.jpg");
MenuGroupImages.set("Kartoffelauflauf", "fd3afb03-0ebc-41b2-bf23-0eb77efb31ce.jpg");
MenuGroupImages.set("Kuchen", "1f8ec0ce-57a6-4844-8a72-5286041d02ee.jpg");
MenuGroupImages.set("Käsespätzle", "9fee7917-8671-4a5d-972c-81af3c6e8f7e.jpg");
MenuGroupImages.set("Köfte", "df6bd842-c7c2-45c6-9734-38c970eee4b1.jpg");
MenuGroupImages.set("Lahmacun", "e6551790-9f92-4ee0-984d-638152db7d0b.jpg");
MenuGroupImages.set("Lasagne", "242d462f-cf06-4cb0-8248-22a5a743d160.jpg");
MenuGroupImages.set("Mango Lassi", "60d3eaef-90ac-40b3-8f77-d6397c861c3b.jpg");
MenuGroupImages.set("Mango Reis Eis ", "acec3c34-f3d8-4525-99ac-0cf6f1c32277.jpg");
MenuGroupImages.set("Panini", "b9911ca2-6bb7-4077-94f5-41de348250e5.jpg");
MenuGroupImages.set("Pasta", "9d9bd654-d3bf-49e5-8a18-15869dd02e16.jpg");
MenuGroupImages.set("Ravioli", "31f2c9af-b8b4-41f0-a97f-cc0cccfa55d9.jpg");
MenuGroupImages.set("Pide", "01dac217-f7ad-4afd-9e40-08e3e2fb6049.jpg");
MenuGroupImages.set("Pizza", "731093a1-4406-45c3-8a2e-7c828777fb84.jpg");
MenuGroupImages.set("Pizza 2", "1a45b59d-59c7-44d5-995f-1ce6f52e4d52.jpg");
MenuGroupImages.set("Pizza Party", "a290f27c-6ab3-4a50-96d1-372ed269f6b6.jpg");
MenuGroupImages.set("Pizzabrot", "9d0e8e52-3272-43f5-a7e7-5516a0c0cc4e.jpg");
MenuGroupImages.set("Pizzabrötchen", "6418f0b4-9ff1-4501-bced-e8b910b7c040.jpg");
MenuGroupImages.set("Pommes", "10fa576d-d411-4425-82e3-e389705759b4.jpg");
MenuGroupImages.set("Reisgericht", "e0e51dfd-64d0-4e08-bdec-6f61d82f84e8.jpg");
MenuGroupImages.set("Risotto", "f075db08-89e6-4daf-84f3-b4d02e9bc713.jpg");
MenuGroupImages.set("Salat", "b5518fd8-5e7d-40ff-8863-066f7c0a719c.jpg");
MenuGroupImages.set("Salat 2", "98263a89-42b0-413b-9fc3-32653a00162d.jpg");
MenuGroupImages.set("Sandwich", "038948bb-ef69-4e89-b676-066396d577d1.jpg");
MenuGroupImages.set("Saucen", "d1aebb11-e18f-4cde-aac8-b57642a4211e.jpg");
MenuGroupImages.set("Schnitzel", "0d99eb12-8dbd-4b27-8eb9-2ae8e5a9e6a8.jpg");
MenuGroupImages.set("Spaghetti Bolognese", "580009bf-e223-4314-a92f-877fc26ed083.jpg");
MenuGroupImages.set("Steak", "930647e5-0ad1-4568-8c51-c84a7cb0dc72.jpg");
MenuGroupImages.set("Suppe (Kürbis)", "c83c422d-201d-41eb-a4d9-209099f052a6.jpg");
MenuGroupImages.set("Suppe (Nudeln)", "3a23c971-d17d-4629-89a5-026d7d1eda6a.jpg");
MenuGroupImages.set("Tacos", "ee8bceda-69e0-4c15-b3df-3be4f0bfe7f6.jpg");
MenuGroupImages.set("Tagliatelle", "374f2aa3-c81b-43ca-aa25-c6b2e312348a.jpg");
MenuGroupImages.set("Thai Curry", "c7318633-7a6c-49b4-95fb-7e3bd5c2c9ec.jpg");
MenuGroupImages.set("Tiramisu", "48d261ad-9baf-4f73-9b0e-463cb51bcc63.jpg");
MenuGroupImages.set("Tortellini", "e9947abf-e32e-4561-8f2a-1f08e32c9aae.jpg");
MenuGroupImages.set("Tzaziki", "0764bc08-ac78-4099-9333-60e1c871a0f4.jpg");
MenuGroupImages.set("Vorspeisen", "d286117c-7c98-499d-8d50-0d477046fa1d.jpg");
MenuGroupImages.set("Wrap", "d203a01b-db7c-450c-a930-b8c1db23d217.jpg");
MenuGroupImages.set("Wurst", "d0abe5ed-dc90-423d-b25f-c19860de78b7.jpg");

export { MenuGroupImages };