import { Order } from '@bestelleck/utils';

import { baseUrl } from '../util/constants';
import { customFetch } from '../util/utils';

export const getOrder = async (orderId: string): Promise<Order> => {
    const url = `${baseUrl}/orders/${orderId}?useSelectionGroups=true`;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', pragma: 'no-cache', 'cache-control': 'no-cache' },
    };
    return customFetch(url, requestOptions);
};

export const updateOrderStatus = async (
    orderId: string,
    orderStatus: 'open' | 'cancelled_before_pay',
): Promise<Order> => {
    const url = `${baseUrl}/orders/${orderId}/status?useSelectionGroups=true`;
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status: orderStatus }),
    };
    return customFetch(url, requestOptions);
};
