import { Allergens } from "./allergens.enum";

export function getAllergens(allergenCodes: string[]): string[] {
    const result: string[] = [];
    for (const code of allergenCodes) {
        const allergen = (Allergens as any)[code];
        if (!allergen) continue;
        if (code.length > 1) {
            const allergenCategory = (Allergens as any)[code[0]];
            if (!result.includes(allergenCategory)) {
                result.push(allergenCategory);
            }
        }
        result.push(allergen);
    }
    return result;
}
