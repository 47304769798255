import React from 'react';

import { Description } from '@bestelleck/utils';
import { Collapse } from '@mui/material';

import { getDescriptionText, isDescriptionsExpandable, seperateDescriptions } from '../../util/descriptions';

import styles from './Description.module.scss';

interface DescriptionComponentProps {
    descriptions: Description[];
    expanded: boolean;
}

const DescriptionComponent: React.FC<DescriptionComponentProps> = ({ descriptions, expanded }) => {
    const canExpand = isDescriptionsExpandable(descriptions);
    if (!canExpand) {
        return (
            <div className={styles.root}>
                {descriptions.map((desc, index) => {
                    return <div key={index}>{getDescriptionText(desc)}</div>;
                })}
            </div>
        );
    } else if (canExpand) {
        const [defaultDescriptions, expandedDescriptions] = seperateDescriptions(descriptions);
        return (
            <div className={styles.root}>
                {defaultDescriptions.map((desc, index) => {
                    return <div key={index}>{getDescriptionText(desc)}</div>;
                })}
                {!expanded && <div className={styles.more}>Weiterlesen</div>}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {expandedDescriptions.map((desc, index) => {
                        return <div key={index}>{getDescriptionText(desc)}</div>;
                    })}
                </Collapse>
            </div>
        );
    }
    return <></>;
};

export default DescriptionComponent;
