import React from 'react';

import { Extra, ExtraGroup, MenuItem, MenuItemOption, SelectionGroupType } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FormControl, NativeSelect, Typography } from '@mui/material';

import DescriptionComponent from '../../../../../components/Description/Description';
import { ItemOption } from '../../../../../store/types/cart.types';
import { formatPrice } from '../../../../../util/price';
import { getUniqueKey } from '../../../../../util/utils';
import ExtraGroupComponent from '../../ExtraGroup/ExtraGroup';
import { MappedExtraGroup, getInitalExtras, getInitialOptions, resolveSelectionGroups } from '../../MenuItem.utils';

import styles from './MenuItemDetails.module.scss';

type MenuItemProps = {
    extras: Extra[];
    item: MenuItem;
    selectedOptions: ItemOption[];
    initalExtras: MappedExtraGroup[];
    selectedExtras: MappedExtraGroup[];
    selectionGroups: (MenuItemOption | ExtraGroup)[];
    isDelivery: boolean;
    setExtras: (extras: MappedExtraGroup[]) => void;
    setOptions: (options: ItemOption[]) => void;
};

export const MenuItemDetailsComponent = React.forwardRef<HTMLDivElement, MenuItemProps>(function ProductDialog(
    props,
    ref,
) {
    const {
        item,
        extras,
        initalExtras,
        isDelivery,
        selectedExtras,
        selectedOptions,
        setExtras,
        setOptions,
        selectionGroups,
        ...rest
    } = props;
    const { trackEvent } = useMatomo();
    const initialOptions = getInitialOptions(item, selectionGroups, isDelivery);
    const resolvedSelectionGroups = resolveSelectionGroups(item, selectionGroups, selectedOptions);

    const handleOptionChange = (option: MenuItemOption, event: any, index: number) => {
        const value = option.values.find((val) => val.id === event.target.value);
        if (value) {
            let newOptions: ItemOption[];
            if (value.selectionGroupIds.length > 0) {
                newOptions = [...initialOptions];
            } else {
                newOptions = [...selectedOptions];
            }
            newOptions[index].selectedValue = value;
            const currentExtras = getInitalExtras(item, selectionGroups, extras, newOptions);

            const newExtras = currentExtras.map((extraGroup, groupIndex) => {
                return {
                    ...extraGroup,
                    extras: extraGroup.extras.map((extra) => {
                        const foundExtraGroup = selectedExtras[groupIndex];
                        if (!foundExtraGroup) {
                            return { ...extra };
                        }
                        const foundSelectedExtra = selectedExtras[groupIndex].extras.find(
                            (selected) => selected.id === extra.id,
                        );
                        if (foundSelectedExtra && foundSelectedExtra.selected) {
                            return {
                                ...extra,
                                selected: true,
                            };
                        }
                        return { ...extra };
                    }),
                };
            });
            setExtras(newExtras);
            setOptions(newOptions);
            trackEvent({
                action: 'Change option',
                category: 'Menu Item',
                name: `${item.name}-${option.name}-${value.name}`,
            });
        }
    };

    const handleExtraChange = (group: number, itemId: string) => {
        const updatedCheckedState = selectedExtras.map((extraGroup, groupIndex) => {
            return {
                ...extraGroup,
                extras: extraGroup.extras.map((extraItem) => {
                    if (extraItem.id === itemId && group === groupIndex) {
                        trackEvent({
                            action: 'Extra',
                            category: 'Menu Item',
                            name: `${item.name}-${extraItem.name}-${!extraItem.selected}`,
                        });
                        return { ...extraItem, selected: !extraItem.selected };
                    }
                    return extraItem;
                }),
            };
        });
        setExtras(updatedCheckedState);
    };

    const basePrice = (isDelivery ? item.basePrice.delivery : item.basePrice.pickup) as number;
    let extraIndex = -1;
    let optionIndex = -1;
    const groups = resolvedSelectionGroups.map((group) => {
        if (group.type === SelectionGroupType.Option) {
            optionIndex += 1;
            const filteredItems = group.values.filter((value) => {
                if (
                    (isDelivery && value.priceModifier.delivery !== undefined) ||
                    (!isDelivery && value.priceModifier.pickup !== undefined)
                ) {
                    return true;
                }
                return false;
            });
            const items = filteredItems.map((value) => {
                const valueModifier = (
                    isDelivery ? value.priceModifier.delivery : value.priceModifier.pickup
                ) as number;
                if (optionIndex === 0) {
                    return (
                        <option key={getUniqueKey()} value={value.id}>{`${value.name} (${formatPrice(
                            basePrice + valueModifier,
                        )})`}</option>
                    );
                }

                return (
                    <option key={getUniqueKey()} value={value.id}>{`${value.name} (+ ${formatPrice(
                        valueModifier,
                    )})`}</option>
                );
            });
            if (items.length === 0) {
                return <div key={getUniqueKey()}></div>;
            }
            const selectedOption = selectedOptions[optionIndex].selectedValue;

            if (selectedOption) {
                const localIndex = optionIndex;
                return (
                    <FormControl key={getUniqueKey()} className={styles.optionGroup}>
                        <span className={styles.sectionHeader}>{group.name}</span>
                        <NativeSelect
                            className={styles.select}
                            id="option-select"
                            value={selectedOption.id}
                            onChange={(event) => {
                                handleOptionChange(group, event, localIndex);
                            }}
                        >
                            {items}
                        </NativeSelect>
                    </FormControl>
                );
            }
            return <div key={getUniqueKey()}></div>;
        } else if (group.type === SelectionGroupType.ExtraGroup) {
            extraIndex += 1;
            return (
                <ExtraGroupComponent
                    key={`extraGroup${extraIndex}-${item.id}`}
                    extraGroup={group}
                    extras={extras}
                    groupIndex={extraIndex}
                    handleExtraChange={handleExtraChange}
                    isDelivery={isDelivery}
                    item={item}
                    selectedExtras={selectedExtras}
                ></ExtraGroupComponent>
            );
        }
        return <></>;
    });

    let itemBasePrice = (isDelivery ? item.basePrice.delivery : item.basePrice.pickup) as number;
    if (item.options && item.options.length > 0) {
        const firstOption = item.options[0].values[0];
        itemBasePrice += (isDelivery ? firstOption.priceModifier.delivery : firstOption.priceModifier.pickup) as number;
    }
    return (
        <div ref={ref} {...rest}>
            <div className={styles.root}>
                <div className={styles.text}>
                    <DescriptionComponent expanded={true} descriptions={item.descriptions} />
                    <Typography variant="h6" color="textSecondary" className={styles.price}>
                        {formatPrice(itemBasePrice)}
                    </Typography>
                </div>
                {groups.length > 0 && (
                    <div className={styles.optionContainer}>
                        <div className={styles.options}>{groups}</div>
                    </div>
                )}
            </div>
        </div>
    );
});
