import React, { useEffect, useState } from 'react';

import { Order } from '@bestelleck/utils';
import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { captureException } from '@sentry/react';
import { useDispatch } from 'react-redux';

import { getOrder, updateOrderStatus } from '../../services/order.service';
import { Dispatch } from '../../store/store';
import { restaurantId } from '../../util/constants';
import { useQuery } from '../../util/utils';

import styles from './Completed.module.scss';
import { ErrorDetailsComponent } from './ErrorDetails/ErrorDetails';
import { OrderDetailsComponent } from './Order/OrderDetails';
import { CheckoutSkeletonComponent } from './Skeleton/Skeleton';

const CompletedScreen: React.FC = () => {
    const dispatch = useDispatch<Dispatch>();

    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [displayOrder, setOrder] = useState<Order>();

    const query = useQuery();
    const id = restaurantId;
    const token = query.get('token');
    const payerId = query.get('PayerID');
    const orderId = query.get('orderId');

    const paymentIntent = query.get('payment_intent');
    const paymentIntentSecret = query.get('payment_intent_client_secret');

    useEffect(() => {
        setLoading(true);

        const getAndUpdateOrder = async () => {
            if (orderId) {
                try {
                    const order = await getOrder(orderId);

                    setOrder(order);
                    const orderStatus = order?.status.transitions[order.status.transitions.length - 1].status;
                    const payment = order.payment.option;
                    const isPayPal = payment === 'paypal';
                    const isStripe = payment === 'card' || payment === 'giropay';
                    const isApplePay = payment === 'applepay';
                    if (isPayPal && orderStatus === 'unpayed' && (!token || !payerId)) {
                        throw new Error('Paypal details missing');
                    }
                    if (isStripe && orderStatus === 'unpayed' && (!paymentIntent || !paymentIntentSecret)) {
                        throw new Error('Stripe details missing');
                    }
                    if ((isPayPal || isStripe || isApplePay) && orderStatus === 'unpayed') {
                        const updatedOrder = await updateOrderStatus(orderId, 'open');
                        setOrder(updatedOrder);
                    }
                    dispatch.cart.clearCart();
                    setLoading(false);
                } catch (error: any) {
                    captureException(error);
                    setLoading(false);
                    setError(true);
                }
            } else {
                setLoading(false);
                setError(true);
            }
        };
        getAndUpdateOrder();
    }, [isError, orderId, dispatch, payerId, paymentIntent, paymentIntentSecret, token]);

    return (
        <div className={styles.root}>
            {isError && displayOrder && (
                <ErrorDetailsComponent restaurantId={id} paymentOption={displayOrder.payment.option} />
            )}
            {!isError && displayOrder && <OrderDetailsComponent setLoading={setLoading} order={displayOrder} />}
            {isLoading && <CheckoutSkeletonComponent />}
            <Backdrop sx={{ color: '#fff' }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default CompletedScreen;
