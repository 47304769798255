import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { FormResult } from '../Checkout';

export const NewsletterCheckox: React.FC = () => {
    const { control } = useFormContext<FormResult>();

    return (
        <div>
            <FormControlLabel
                style={{ textAlign: 'left' }}
                control={
                    <Controller
                        name="newsletter"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
                        )}
                    />
                }
                label={'Zum Newsletter anmelden (erhalte Gutscheine, Treueangebote und andere Updates)'}
            />
        </div>
    );
};
