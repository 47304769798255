import { Description } from '@bestelleck/utils';

export const isDescriptionsExpandable = (descriptions: Description[]): boolean => {
    const found = descriptions.find((desc) => !desc.default);
    if (found) {
        return true;
    }
    return false;
};

export const seperateDescriptions = (descriptions: Description[]): [Description[], Description[]] => {
    return [descriptions.filter((desc) => desc.default), descriptions.filter((desc) => !desc.default)];
};

export const getDescriptionText = (description: Description): string => {
    return `${description.isListItem ? '⦁ ' : ''}${description.text}`;
};
