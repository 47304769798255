import { MenuItem } from "./menu-item.interface";
import { MenuTemplate } from "./menu-template.interface";

export const resolveTemplates = (item: MenuItem, templates: MenuTemplate[]): MenuItem => {
    const itemCopy = { ...item };
    if (!item.templateId) {
        return item;
    }
    const template = templates.find((template) => template.id === item.templateId);
    if (!template) {
        return item;
    }

    itemCopy.selectionGroupIds = template.selectionGroupIds.concat(item.selectionGroupIds);
    return itemCopy;
};

export const resolveSelectionGroupsFromTemplates = (item: MenuItem, templates: MenuTemplate[]): MenuItem => {
    const itemCopy = { ...item };
    if (!item.templateId) {
        return item;
    }
    const template = templates.find((template) => template.id === item.templateId);
    if (!template) {
        return item;
    }

    itemCopy.selectionGroupIds = template.selectionGroupIds.concat(item.selectionGroupIds);
    itemCopy.selectionGroupIdsfromTemplate = template.selectionGroupIds;
    return itemCopy;
};
