import {
    Extra,
    ExtraGroup,
    ExtraMapped,
    MenuItem,
    MenuItemOption,
    MenuItemOptionValue,
    SelectionGroupType,
} from '@bestelleck/utils';

import { ItemOption } from '../../../store/types/cart.types';

export const getInitialOptions = (
    item: MenuItem,
    allSelectionGroups: (MenuItemOption | ExtraGroup)[],
    isDelivery: boolean,
): ItemOption[] => {
    const menuItemGroups = [];
    for (const selectionGroupId of item.selectionGroupIds) {
        const group = allSelectionGroups.find((g) => g.id === selectionGroupId);
        if (group) {
            menuItemGroups.push(group);
        }
    }

    const filtered = menuItemGroups.filter((group) => group.type === SelectionGroupType.Option) as MenuItemOption[];
    return filtered.map((option) => {
        const values = option.values.filter((value) => {
            if (
                (isDelivery && value.priceModifier.delivery !== undefined) ||
                (!isDelivery && value.priceModifier.pickup !== undefined)
            ) {
                return true;
            }
            return false;
        });
        return {
            ...option,
            selectedValue: values[0],
        };
    });
};

export const getVisibleOptions = (
    menuItemOptions: MenuItemOption[] | undefined,
    selectedOptions: MenuItemOptionValue[],
): MenuItemOption[] => {
    if (!menuItemOptions) {
        return [];
    }
    const visibleMenuOptions: MenuItemOption[] = menuItemOptions[0] ? [menuItemOptions[0]] : [];

    let menuOptionsPossibilitiesIds = selectedOptions.reduce<string[]>((a, b) => a.concat(b.selectionGroupIds), []);

    while (menuOptionsPossibilitiesIds.length) {
        let tmpMenuOptions: string[] = [];
        menuOptionsPossibilitiesIds.forEach((menuOptionPosId: string) => {
            const newMenuOption = menuItemOptions.find((mio) => mio.id === menuOptionPosId);
            if (newMenuOption) {
                visibleMenuOptions.push(newMenuOption);
                if (newMenuOption.values[0].selectionGroupIds.length) {
                    tmpMenuOptions = tmpMenuOptions.concat(newMenuOption.values[0].selectionGroupIds);
                }
            }
        });
        menuOptionsPossibilitiesIds = tmpMenuOptions;
    }

    return visibleMenuOptions;
};

export type MappedExtraType = ExtraMapped & { selected: boolean };

export type MappedExtraGroup = {
    id: string;
    name: string;
    extras: MappedExtraType[];
    activateByOptionValues?: string[];
    public: boolean;
    type: SelectionGroupType.ExtraGroup;
};

export const getInitalExtras = (
    item: MenuItem,
    allSelectionGroups: (MenuItemOption | ExtraGroup)[],
    extras: Extra[],
    selectedOptions: ItemOption[],
): MappedExtraGroup[] => {
    const groups = [];
    const menuOptionsSelectionGroups = [...item.selectionGroupIds];
    menuOptionsSelectionGroups.push(
        ...selectedOptions.reduce<string[]>((a, b) => a.concat(b.selectedValue.selectionGroupIds), []),
    );
    for (const selectionGroupId of menuOptionsSelectionGroups) {
        const group = allSelectionGroups.find((g) => g.id === selectionGroupId);
        if (group) {
            groups.push(group);
        }
    }
    const filtered = groups.filter((group) => group.type === SelectionGroupType.ExtraGroup) as ExtraGroup[];

    return filtered.map((extraGroup) => {
        const mapped = extraGroup.extras.map((extra) => {
            const found = extras.find((extr) => extr.id === extra.id);
            if (found) {
                return {
                    ...extra,
                    ...found,
                    selected: false,
                };
            }
            return null;
        });
        const filtered = mapped.filter((extra): extra is MappedExtraType => extra !== null);
        return {
            ...extraGroup,
            extras: filtered,
        };
    });
};

export const getMappedExtra = (extraGroup: ExtraGroup, item: MenuItem, extras: Extra[]): ExtraMapped[] => {
    const mappedExtraItem = extraGroup.extras
        .map((extra) => {
            const found = extras.find((extr) => extr.id === extra.id);
            if (found) {
                return { ...found, ...extra };
            }
            return null;
        })
        .filter((extra): extra is ExtraMapped => item !== null);

    return mappedExtraItem;
};

export type VisibleExtraGroup = ExtraGroup & { isVisible: boolean };

export const getExtraGroups = (selectionGroups: (MenuItemOption | ExtraGroup)[]): ExtraGroup[] => {
    const extras = selectionGroups.filter((group) => group.type === SelectionGroupType.ExtraGroup) as ExtraGroup[];
    return extras;
};

export const resolveSelectionGroups = (
    item: MenuItem,
    selectionGroups: (MenuItemOption | ExtraGroup)[],
    selectedOptions: ItemOption[],
): (MenuItemOption | ExtraGroup)[] => {
    const availSelectionGroups = [];
    const menuOptionsSelectionGroups = [...item.selectionGroupIds];
    menuOptionsSelectionGroups.push(
        ...selectedOptions.reduce<string[]>((a, b) => a.concat(b.selectedValue.selectionGroupIds), []),
    );

    for (const selectionGroupId of menuOptionsSelectionGroups) {
        const selectionGroup = selectionGroups.find((group) => group.id === selectionGroupId);
        if (selectionGroup) {
            availSelectionGroups.push(selectionGroup);
        }

        if (selectionGroup && selectionGroup?.type === SelectionGroupType.Option) {
            if (!item.options) {
                item.options = [];
            }
            item.options?.push(selectionGroup);
        } else if (selectionGroup && selectionGroup?.type === SelectionGroupType.ExtraGroup) {
            if (!item.extraGroups) {
                item.extraGroups = [];
            }
            item.extraGroups?.push(selectionGroup);
        }
    }

    return availSelectionGroups;
};

export const resolveSelectionGroupsById = (
    selectionGroupIds: string[],
    selectionGroups: (MenuItemOption | ExtraGroup)[],
): (MenuItemOption | ExtraGroup)[] => {
    const result = [];
    for (const selectionGroupId of selectionGroupIds) {
        const selectionGroup = selectionGroups.find((group) => group.id === selectionGroupId);
        if (selectionGroup) {
            result.push(selectionGroup);
        }
    }
    return result;
};
