import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

interface BusyIndicatorProps {
    isLoading: boolean;
}

export const BusyIndicatorComponent: React.FC<BusyIndicatorProps> = ({ isLoading }) => {
    return (
        <Backdrop sx={{ color: '#fff' }} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
