import React from 'react';

import { getAdditives, getAllergens, MenuItem } from '@bestelleck/utils';

import styles from './ProductInfo.module.scss';

type ProductInfoComponentProps = {
    item: MenuItem;
    hideTitle?: boolean;
};

export const ProductInfoDialogComponent = React.forwardRef<HTMLDivElement, ProductInfoComponentProps>(
    function ProductDialog(props, ref) {
        const parsedAllergens = getAllergens(props.item.allergens);
        const parsedAdditives = getAdditives(props.item.additives);
        return (
            <div ref={ref} {...props} className={styles.root}>
                {!props.hideTitle && <div className={styles.titleHeader}>Produktinformationen</div>}
                <div className={styles.title}>{props.item.name}</div>
                <div className={styles.section}>
                    <div className={styles.header}>Allergene</div>
                    <div className={styles.content}>
                        {parsedAllergens.map((allergen) => (
                            <div key={allergen}>{allergen}</div>
                        ))}
                        {parsedAllergens.length === 0 && <div>Uns sind keine Allergene bekannt</div>}
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.header}>Zusatzstoffe</div>

                    <div className={styles.content}>
                        {parsedAdditives.map((additive) => (
                            <div key={additive}>{additive}</div>
                        ))}
                        {parsedAdditives.length === 0 && <div>Uns sind keine Zusatzstoffe bekannt</div>}
                    </div>
                </div>

                <div className={styles.info}>
                    Wir halten die Informationen zu Allergenen und Zusatzstoffen aktuell basierend auf den
                    Informationen, die wir von den Restaurants erhalten. Es kann jedoch vorkommen, dass die angezeigten
                    Informationen unvollständig sind und/oder von den Restaurants noch nicht auf Korrektheit überprüft
                    wurden. Bitte wende Dich an das Restaurant, wenn Allergien oder Intoleranzen vorliegen oder Du
                    Fragen zu bestimmten Speisen auf der Karte hast.
                </div>
            </div>
        );
    },
);
